// eslint-disable-next-line import/prefer-default-export
export function getAddressDisplay(address) {
  const { address1, address2, city, state, postalCode } = address;

  const addressPart = [address1, address2].filter(Boolean).join(', ');
  const cityStateZipPart = [
    [city, state].filter(Boolean).join(', '),
    postalCode,
  ]
    .filter(Boolean)
    .join(' ');
  return [addressPart, cityStateZipPart].filter(Boolean).join(', ');
}
