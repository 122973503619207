import PropTypes from 'prop-types';
import React, { createContext, useState, useMemo, useCallback } from 'react';
import { useCreatePatientDocument } from 'hooks/patientDocumentHooks';
import { useSiteDocumentTypes } from 'hooks/siteDocumentTypeHooks';

const documentTemplate = {
  id: 0,
  patientId: '',
  type: undefined,
  title: '',
  data: '',
};

const DocumentContext = createContext();

function DocumentProvider({ patientId, siteId, children }) {
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [document, setDocument] = useState(documentTemplate);
  const { data: documentTypes, isLoading } = useSiteDocumentTypes(siteId);

  const createPatientDocument = useCreatePatientDocument({
    onSuccess: () => setShowModal(false),
    onError: (e) => setError(e.response?.data?.message),
  });

  const closeModal = () => {
    setShowModal(false);
  };

  const addDocument = () => {
    setDocument(documentTemplate);
    setError('');
    setShowModal(true);
  };

  const saveDocument = useCallback(
    async (data) => {
      await createPatientDocument.mutate({ patientId, data });
    },
    [createPatientDocument, patientId]
  );

  const value = useMemo(
    () => ({
      document,
      documentTypes,
      error,
      showModal,
      closeModal,
      addDocument,
      saveDocument,
      isBusy: createPatientDocument.isLoading || isLoading,
    }),
    [
      createPatientDocument.isLoading,
      document,
      documentTypes,
      error,
      isLoading,
      saveDocument,
      showModal,
    ]
  );

  return (
    <DocumentContext.Provider value={value}>
      {children}
    </DocumentContext.Provider>
  );
}

DocumentProvider.propTypes = {
  patientId: PropTypes.number.isRequired,
  siteId: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default DocumentProvider;
export { DocumentProvider, DocumentContext };
