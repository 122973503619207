import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Stack from 'react-bootstrap/Stack';
import { Dash } from 'react-bootstrap-icons';
import {
  ACTION_BUTTON_TYPES,
  ActionButton,
} from 'components/Button/ActionButton';
import AddButton from 'components/Button/AddButton';
import ConfirmationDialog from 'components/ConfirmationDialog';
import TableLayout from 'components/Layouts/TableLayout';
import LoadingIndicator from 'components/LoadingIndicator';
import { useDeleteSiteDataSource } from 'hooks/siteDataSourceHooks';
import SiteDataSourceModal from 'pages/Admin/components/SiteDataSourceModal';

function CommonColumn({ name, id }) {
  return (
    <div>
      <div>{name}</div>
      <div className="text-muted">{id}</div>
    </div>
  );
}

CommonColumn.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

function renderSourceColumn(props) {
  return (
    <CommonColumn
      name={props.row.original.sourceName}
      id={props.row.original.sourceId}
    />
  );
}

function renderDestinationColumn(props) {
  return (
    <CommonColumn
      name={props.row.original.destinationName}
      id={props.row.original.destinationId}
    />
  );
}

function renderClinicalSummaryColumn(props) {
  return (
    <CommonColumn
      name={props.row.original.clinicalSummarySourceName ?? <Dash />}
      id={props.row.original.clinicalSummarySourceId ?? <Dash />}
    />
  );
}

export default function AdminSiteDetailsSettingsTab({ site }) {
  const [showModal, setShowModal] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedDataSource, setSelectedDataSource] = useState(null);

  const deleteSiteDataSource = useDeleteSiteDataSource({
    onSuccess: () => {
      setShowConfirm(false);
      setSelectedDataSource(null);
    },
  });

  const onConfirmDelete = () => {
    deleteSiteDataSource.mutate(selectedDataSource);
  };

  const renderActions = (dataSource) => (
    <>
      <ActionButton
        action={ACTION_BUTTON_TYPES.EDIT}
        onClick={() => {
          setSelectedDataSource(dataSource);
          setShowModal(true);
        }}
      />
      <ActionButton
        action={ACTION_BUTTON_TYPES.DELETE}
        onClick={() => {
          setSelectedDataSource(dataSource);
          setShowConfirm(true);
        }}
      />
    </>
  );

  const COLUMNS = [
    {
      header: 'Source',
      cell: (props) => renderSourceColumn(props),
    },
    {
      header: 'Destination',
      cell: (props) => renderDestinationColumn(props),
    },
    {
      accessorKey: 'medicalRecordNumberIdentifier',
      header: 'MRN Identifier',
    },
    {
      header: 'Clinical Summary Source',
      cell: (props) => renderClinicalSummaryColumn(props),
    },
    {
      header: 'Actions',
      meta: {
        headerProps: {
          className: 'col-1 text-center',
        },
        cellProps: {
          className: 'text-center',
        },
      },
      cell: ({ row }) => renderActions(row.original),
    },
  ];

  if (site.isLoading) return <LoadingIndicator />;

  return (
    <Stack gap={2}>
      <AddButton
        onClick={() => {
          setSelectedDataSource({ id: 0 });
          setShowModal(true);
        }}
        label="Data Source"
      />
      <TableLayout columns={COLUMNS} data={site.dataSources} />
      {showModal && (
        <SiteDataSourceModal
          dataSource={selectedDataSource}
          siteId={site.id}
          onClose={() => {
            setShowModal(false);
            setSelectedDataSource(null);
          }}
        />
      )}
      <ConfirmationDialog
        show={showConfirm}
        title="Delete Data Source"
        bodyText="Are you sure you want to delete this data source from this site?"
        confirmText="Delete"
        onConfirm={onConfirmDelete}
        onCancel={() => {
          setShowConfirm(false);
          setSelectedDataSource(null);
        }}
      />
    </Stack>
  );
}

AdminSiteDetailsSettingsTab.propTypes = {
  site: PropTypes.shape({
    id: PropTypes.number,
    dataSources: PropTypes.arrayOf(PropTypes.shape({})),
    isLoading: PropTypes.bool,
  }).isRequired,
};
