import PropTypes from 'prop-types';
import React from 'react';
import { Stack } from 'react-bootstrap';
import DocumentsSection from './components/DocumentsSection';
import NotesSection from './components/NotesSection';
import PatientIndicationsSection from './components/PatientIndicationsSection';
import PatientRegistriesSection from './components/PatientRegistriesSection';
import PatientStudiesSection from './components/PatientStudiesSection';
import PatientAppointmentsSection from './components/PrescreeningAppointmentsSection';
import { partition } from 'pages/utils/helpers';

export default function ResearchTab({ patient }) {
  const { id: patientId, siteId } = patient;

  const [patientRegistries, patientStudies] = partition(
    patient.patientStudies,
    (ps) => ps.study.isRegistry
  );

  return (
    <Stack gap={2} className="bg-white py-3 px-3">
      <PatientIndicationsSection patientId={patientId} />
      <hr />
      <PatientStudiesSection
        patientId={patientId}
        patientStudies={patientStudies}
        siteStudies={patient.site.studies}
      />
      <hr />
      <PatientAppointmentsSection patientId={patientId} />
      <hr />
      <PatientRegistriesSection
        patientRegistries={patientRegistries.filter(
          (pr) => pr.studyStatusId != null
        )}
      />
      <hr />
      <NotesSection patientId={patientId} />
      <hr />
      <DocumentsSection patientId={patientId} siteId={siteId} />
      <hr />
    </Stack>
  );
}

ResearchTab.propTypes = {
  patient: PropTypes.shape({
    id: PropTypes.number.isRequired,
    siteId: PropTypes.number.isRequired,
    patientStudies: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        study: PropTypes.shape({
          id: PropTypes.number.isRequired,
          isRegistry: PropTypes.bool.isRequired,
        }).isRequired,
      })
    ).isRequired,
    site: PropTypes.shape({
      id: PropTypes.number.isRequired,
      studies: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
        })
      ).isRequired,
    }).isRequired,
  }).isRequired,
};
