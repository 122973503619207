import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import {
  Row,
  Col,
  Card,
  Stack,
  DropdownButton,
  Dropdown,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import CancelledAppointmentsTable from './CancelledAppointmentsTable';
import CompletedEncountersTable from './CompletedEncountersTable';
import InProcessAppointmentCard from './InProcessAppointmentCard';
import UpcomingAppointmentCard from './UpcomingAppointmentCard';
import AppointmentCancellationModal from 'components/Encounter/AppointmentCancellationModal';
import AppointmentModal from 'components/Encounter/AppointmentModal';
import LoadingIndicator from 'components/LoadingIndicator';
import { UserContext, Roles } from 'components/Provider/UserProvider';
import AppointmentTypes from 'entities/AppointmentTypes';
import EncounterStatuses from 'entities/EncounterStatuses';
import { useEncounterStatuses, useEncounterTypes } from 'hooks/encounterHooks';
import { usePatientAppointments } from 'hooks/patientAppointmentHooks';
import { useCreatePatientEncounter } from 'hooks/patientEncounterHooks';
import { usePatient } from 'hooks/patientHooks';

export default function PrescreeningAppointmentsSection({ patientId }) {
  const { userRole } = useContext(UserContext);
  let allowChanges = Roles.canModifyRecords(userRole);

  const navigate = useNavigate();

  const [selectedAppointment, setSelectedAppointment] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [appointmentToCancel, setAppointmentToCancel] = useState(null);
  const { data: patient, isLoading: isPatientLoading } = usePatient(patientId);
  const { data: appointments, isLoading: appointmentsLoading } =
    usePatientAppointments(patientId, AppointmentTypes.OBJECTIVE_SCREEN);
  const { data: encounterTypes, isLoading: encounterTypesLoading } =
    useEncounterTypes();
  const { data: encounterStatuses, isLoading: encounterStatusesLoading } =
    useEncounterStatuses();

  const navigateToEncounter = (encounter) => {
    navigate(`/patients/${encounter.patientId}/encounters/${encounter.id}`);
  };

  const createPatientEncounter = useCreatePatientEncounter({
    onSuccess: (encounter) => navigateToEncounter(encounter),
  });

  const getEncounterStatusByName = (name) =>
    encounterStatuses.find((encounterStatus) => encounterStatus.name === name);

  const createEncounter = (appointment) => {
    const encounterStatus = getEncounterStatusByName(
      EncounterStatuses.INPROCESS
    );

    const encounter = {
      patientId,
      startDate: new Date(),
      encounterTypeId: appointment.encounterTypeId,
      encounterStatusId: encounterStatus.id,
      patientAppointmentId: appointment?.id ?? null,
      siteId: appointment.siteId,
    };

    createPatientEncounter.mutate(encounter);
  };

  const createDraftEncounter = (encounterTypeId) => {
    navigate(
      `/patients/${patientId}/encounters/types/${encounterTypeId}/draft`
    );
  };

  const handleRescheduleAppointment = (appointment) => {
    setSelectedAppointment(appointment);
    setShowModal(true);
  };

  const upcomingAppointments = appointments?.filter(
    (appointment) => !appointment.encounter && !appointment.cancellationDate
  );

  const inProcessAppointments = appointments?.filter(
    (appointment) =>
      appointment.encounter &&
      appointment.encounter.status.name === EncounterStatuses.INPROCESS &&
      !appointment.cancellationDate
  );

  const completedAppointments = appointments?.filter(
    (appointment) =>
      appointment.encounter &&
      appointment.encounter.status.name === EncounterStatuses.COMPLETED &&
      !appointment.cancellationDate
  );

  const cancelledAppointments = appointments?.filter((appt) =>
    Boolean(appt.cancellationDate)
  );

  if (
    appointmentsLoading ||
    encounterTypesLoading ||
    encounterStatusesLoading ||
    isPatientLoading
  ) {
    return <LoadingIndicator />;
  }

  allowChanges = patient.doNotContact ? false : allowChanges;

  return (
    <>
      <Row className="align-items-center">
        <Col>
          <div className="display-3">
            In Process &amp; Upcoming Appointments
          </div>
        </Col>
        <Col xs={3}>
          {allowChanges && (
            <DropdownButton
              title="Prescreen Visit"
              id="prescreen-visit-dropdown"
              variant="outline-primary"
            >
              {encounterTypes.map((encounterType) => (
                <Dropdown.Item
                  key={`encounter-type-${encounterType.id}`}
                  onClick={() => createDraftEncounter(encounterType.id)}
                >
                  {encounterType.name}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          )}
        </Col>
      </Row>
      <Row className="mt-2">
        {inProcessAppointments.length || upcomingAppointments.length ? (
          inProcessAppointments
            .map((appointment) => (
              <Col className="mb-3 flex-grow-0 mx-2" key={appointment.id}>
                <InProcessAppointmentCard
                  appointment={appointment}
                  navigateToEncounter={navigateToEncounter}
                  onDeleteEncounter={() => setAppointmentToCancel(appointment)}
                  isBusy={Boolean(appointmentToCancel)}
                  isReadOnly={!allowChanges}
                />
              </Col>
            ))
            .concat(
              upcomingAppointments.map((appointment) => (
                <Col className="mb-3 flex-grow-0 mx-2" key={appointment.id}>
                  <UpcomingAppointmentCard
                    appointment={appointment}
                    createEncounter={createEncounter}
                    cancelAppointment={() =>
                      setAppointmentToCancel(appointment)
                    }
                    rescheduleAppointment={handleRescheduleAppointment}
                    isBusy={
                      Boolean(appointmentToCancel) ||
                      createPatientEncounter.isLoading
                    }
                    isReadOnly={!allowChanges}
                  />
                </Col>
              ))
            )
        ) : (
          <Col>
            <Card body className="text-muted fst-italic mb-3">
              0 upcoming or in process appointments. Click Add Prescreen Visit
              to add a new prescreen visit.
            </Card>
          </Col>
        )}
      </Row>
      <Stack gap={2}>
        <div className="border-bottom border-lighter" />
        <div className="display-3">Cancelled Appointments</div>
        <CancelledAppointmentsTable appointments={cancelledAppointments} />
        <div className="border-bottom border-lighter" />
        <div className="display-3">Completed Appointments</div>
        <CompletedEncountersTable appointments={completedAppointments} />
      </Stack>

      {showModal && (
        <AppointmentModal
          appointment={selectedAppointment}
          siteId={selectedAppointment.siteId}
          timeZone={selectedAppointment.patient.site.zone.name}
          onClose={() => setShowModal(false)}
          allowChanges={allowChanges}
        />
      )}

      {!!appointmentToCancel && (
        <AppointmentCancellationModal
          onClose={() => setAppointmentToCancel(null)}
          siteId={patient.siteId}
          appointment={appointmentToCancel}
        />
      )}
    </>
  );
}

PrescreeningAppointmentsSection.propTypes = {
  patientId: PropTypes.number.isRequired,
};
