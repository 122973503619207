import PropTypes from 'prop-types';
import React from 'react';
import Select, { components } from 'react-select';

const overrideHeights = { minHeight: 0, maxHeight: 28 };

const customTheme = {
  colors: {
    primary: '#e35205',
    primary75: '#fa7832',
    primary50: '#fca576',
    primary25: '#fdd2ba',
    danger: '#e35205',
    dangerLight: '#fdd2ba',
  },
};

const customStyles = {
  placeholder: (base) => ({ ...base, ...overrideHeights }),
  control: (base) => ({ ...base, ...overrideHeights }),
  valueContainer: (base) => ({
    ...base,
    maxHeight: 22,
    marginBottom: 5,
  }),
  indicatorsContainer: (base) => ({
    ...base,
    ...overrideHeights,
    paddingTop: 6,
    paddingBottom: 6,
  }),
  multiValue: (base, state) =>
    state.data.isFixed
      ? { ...base, backgroundColor: '#38484e' }
      : { ...base, maxHeight: 18, marginRight: 4 },
  multiValueLabel: (base, state) =>
    state.data.isFixed
      ? {
          ...base,
          fontWeight: 'bold',
          color: 'white',
          fontFamily: 'Bicyclette',
          paddingRight: 6,
        }
      : {
          base,
          fontFamily: 'Bicyclette',
          fontSize: '1.1rem',
          paddingLeft: 3,
          alignSelf: 'center',
        },
  multiValueRemove: (base, state) =>
    state.data.isFixed ? { ...base, display: 'none' } : { ...base },
};

function ValueContainer({ children, ...props }) {
  const { getValue } = props;
  const nbValues = getValue().length;
  const newChildren =
    nbValues > 1
      ? [
          <div key="label" className="">{`${nbValues} options selected`}</div>,
          children[1],
        ]
      : children;

  return (
    <components.ValueContainer {...props}>
      {newChildren}
    </components.ValueContainer>
  );
}

ValueContainer.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  getValue: PropTypes.func.isRequired,
};

export function buildOptions(data, valueSelector, labelSelector) {
  return (
    data?.map((d) => {
      const value =
        typeof valueSelector === 'function'
          ? valueSelector(d)
          : d[valueSelector];
      const label =
        typeof labelSelector === 'function'
          ? labelSelector(d)
          : d[labelSelector];

      return { value, label };
    }) ?? []
  );
}

export function getGroupedOptions(
  data,
  discriminator,
  valueSelector,
  labelSelector
) {
  let groupedOptions = [];

  groupedOptions = data.reduce((acc, curr) => {
    const label =
      typeof discriminator === 'function'
        ? discriminator(curr)
        : curr[discriminator];

    if (!acc.some((group) => group.label === label)) {
      acc.push({
        label,
        options: buildOptions(
          // filter data on the current discriminator to return as options
          data.filter((row) =>
            typeof discriminator === 'function'
              ? discriminator(row) === label
              : row[discriminator] === label
          ),
          valueSelector,
          labelSelector
        ),
      });
    }

    return acc;
  }, []);

  return groupedOptions;
}

export default function MultiSelect({
  name,
  onChange,
  selectedValues,
  className,
  placeholderText,
  options,
}) {
  return (
    <Select
      components={{ ValueContainer }}
      hideSelectedOptions={false}
      isSearchable={false}
      isMulti
      name={name}
      placeholder={placeholderText}
      noOptionsMessage={() => 'No options available for logged in user.'}
      options={options}
      value={selectedValues}
      onChange={(val) => onChange(val)}
      className={className}
      classNamePrefix="select"
      styles={customStyles}
      theme={(theme) => ({
        ...theme,
        colors: { ...theme.colors, ...customTheme.colors },
      })}
    />
  );
}

MultiSelect.defaultProps = {
  className: undefined,
};

MultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  className: PropTypes.string,
  placeholderText: PropTypes.string.isRequired,
};
