import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from '../axios';

const createSiteDataSource = async (siteDataSource) => {
  const result = await axios.post(
    `/api/admin/sites/${siteDataSource.siteId}/data-sources`,
    siteDataSource
  );
  return result.data;
};

export function useCreateSiteDataSource(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createSiteDataSource,
    onSuccess: (siteDataSource) => {
      queryClient.invalidateQueries(['site', siteDataSource.siteId]);

      options?.onSuccess?.(siteDataSource);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const updateSiteDataSource = async (siteDataSource) => {
  const result = await axios.put(
    `/api/admin/sites/${siteDataSource.siteId}/data-sources/${siteDataSource.id}`,
    siteDataSource
  );
  return result.data;
};

export function useUpdateSiteDatSource(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateSiteDataSource,
    onSuccess: (siteDataSource) => {
      queryClient.invalidateQueries(['site', siteDataSource.siteId]);

      options?.onSuccess?.(siteDataSource);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const deleteSiteDataSource = async (siteDataSource) => {
  const result = await axios.delete(
    `/api/admin/sites/${siteDataSource.siteId}/data-sources/${siteDataSource.id}`
  );
  return result.data;
};

export function useDeleteSiteDataSource(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteSiteDataSource,
    onSuccess: (siteDataSource) => {
      queryClient.invalidateQueries(['site', siteDataSource.siteId]);

      options?.onSuccess?.(siteDataSource);
    },
    onError: (error) => options?.onError?.(error),
  });
}
