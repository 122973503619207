import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Card, Stack } from 'react-bootstrap';

export default function InProcessAppointmentCard({
  appointment,
  navigateToEncounter,
  onDeleteEncounter,
  isBusy,
  isReadOnly,
}) {
  const timeZone = appointment.patient.site.zone.name;
  const appointmentDateTime = DateTime.fromISO(
    appointment.encounter.startDate,
    { zone: timeZone }
  ).toFormat('EEEE, MMMM d, yyyy, h:mm a ZZZZ');

  const matches = /^(.*), (.*)$/g.exec(appointmentDateTime);
  const date = matches[1];
  const time = matches[2];

  return (
    <Card
      className="mt-2 border-warning text-center h-100"
      style={{ width: '240px' }}
    >
      <Card.Header className="bg-warning text-white display-4">
        In Process
      </Card.Header>
      <Card.Body className="flex-column">
        {appointment.site && (
          <p className="display-3 mb-1">{appointment.site.name}</p>
        )}

        <Stack>
          <small className="h5 opacity-50">Start Date</small>
          <div>{date}</div>
          <div>{time}</div>
        </Stack>

        <Stack className="mt-2" gap={1}>
          <Button
            variant="dark"
            className="w-100"
            onClick={() => navigateToEncounter(appointment.encounter)}
            disabled={isReadOnly || isBusy}
          >
            Continue
          </Button>
          <Button
            variant="outline-dark"
            className="w-100"
            onClick={() => onDeleteEncounter(appointment.encounter.id)}
            disabled={isReadOnly || isBusy}
          >
            Cancel
          </Button>
        </Stack>
      </Card.Body>
    </Card>
  );
}

InProcessAppointmentCard.propTypes = {
  appointment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    encounter: PropTypes.shape({
      id: PropTypes.string.isRequired,
      startDate: PropTypes.string.isRequired,
    }).isRequired,
    site: PropTypes.shape({
      name: PropTypes.string,
    }),
    patient: PropTypes.shape({
      site: PropTypes.shape({
        zone: PropTypes.shape({
          name: PropTypes.string,
        }),
      }),
    }),
    appointmentDate: PropTypes.string.isRequired,
  }).isRequired,
  navigateToEncounter: PropTypes.func.isRequired,
  onDeleteEncounter: PropTypes.func.isRequired,
  isBusy: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
};
