import PropTypes from 'prop-types';
import React, { useContext, useMemo, useState } from 'react';
import {
  Badge,
  FormCheck,
  OverlayTrigger,
  Popover,
  PopoverBody,
  PopoverHeader,
  Stack,
  Table,
} from 'react-bootstrap';
import { Dash } from 'react-bootstrap-icons';
import StudyStatusDropdown from './StudyStatusDropdown';
import StudyStatusReasonModal from './StudyStatusReasonModal';
import { EmptyTableMessage } from 'components/Layouts/TableLayout';
import { Roles, UserContext } from 'components/Provider/UserProvider';
import StudyStatuses from 'entities/StudyStatuses';
import { usePatientStudyHistory } from 'hooks/patientStudyHistoryHooks';
import {
  useCreatePatientStudy,
  useUpdatePatientStudyStatus,
} from 'hooks/patientStudyHooks';
import { useStudyStatuses } from 'hooks/studyStatusHooks';
import { formatDateDisplay } from 'pages/utils/dateTimeUtils';

const findStudyStatus = (studyStatuses, statusName) =>
  studyStatuses.find((s) => s.name === statusName);

export default function PatientStudiesSection({
  patientId,
  patientStudies,
  siteStudies,
}) {
  const { userRole } = useContext(UserContext);
  const [modalValues, setModalValues] = useState({});
  const [showActiveStudies, setShowActiveStudies] = useState(true);

  const createPatientStudy = useCreatePatientStudy();

  const studyHistory = usePatientStudyHistory(patientId);

  const updatePatientStudyStatus = useUpdatePatientStudyStatus();

  const studyStatuses = useStudyStatuses();

  const notInterestedStudyStatus = useMemo(() => {
    if (!studyStatuses.data) return null;

    return findStudyStatus(studyStatuses.data, StudyStatuses.NOT_INTERESTED);
  }, [studyStatuses]);

  const screenFailStudyStatus = useMemo(() => {
    if (!studyStatuses.data) return null;

    return findStudyStatus(studyStatuses.data, StudyStatuses.SCREEN_FAIL);
  }, [studyStatuses]);

  const handleStudyStatusChange = (
    studyId,
    studyStatusId,
    patientStudyId,
    reason
  ) => {
    if (patientStudyId) {
      updatePatientStudyStatus.mutate({
        patientId,
        patientStudyId,
        studyStatusId,
        reason,
      });
    } else {
      createPatientStudy.mutate({
        patientId,
        studyId,
        studyStatusId,
        reason,
      });
    }
  };

  const handleStudyStatusOnChange = (
    studyId,
    updatedStatusId,
    patientStudyId
  ) => {
    switch (Number(updatedStatusId)) {
      case notInterestedStudyStatus.id:
        setModalValues({
          studyId,
          patientStudyId,
          studyStatusId: Number(updatedStatusId),
          formFieldLabel: 'Not Interested Reason',
        });
        break;
      case screenFailStudyStatus.id:
        setModalValues({
          studyId,
          patientStudyId,
          studyStatusId: Number(updatedStatusId),
          formFieldLabel: 'Screen Fail Reason',
        });
        break;
      default:
        handleStudyStatusChange(studyId, updatedStatusId, patientStudyId);
        break;
    }
  };

  if (studyHistory.isLoading || studyStatuses.isLoading) return null;

  const studies = siteStudies.filter(
    (s) => s.active === showActiveStudies && s.isRegistry === false
  );

  const recommendedStudyHistory = studyHistory.data
    ?.filter((item) => item.newStudyStatus?.name === StudyStatuses.RECOMMENDED)
    .map((item) => ({ study: item.study, date: item.createdAt }));

  return (
    <Stack>
      <div className="display-3">Studies</div>
      <Stack direction="horizontal" className="my-2">
        <FormCheck
          id="studyStatus-active"
          type="radio"
          name="studyStatus"
          label="Active"
          checked={showActiveStudies}
          onChange={() => setShowActiveStudies(true)}
        />

        <FormCheck
          id="studyStatus-inactive"
          className="ms-3"
          type="radio"
          name="studyStatus"
          label="Inactive"
          checked={!showActiveStudies}
          onChange={() => setShowActiveStudies(false)}
        />
      </Stack>

      {studies.length ? (
        <Table>
          <thead>
            <tr className="text-uppercase">
              <th>Name</th>
              <th className="text-center">Last Updated</th>
              <th className="text-center">Study Status</th>
            </tr>
          </thead>
          <tbody
            className={showActiveStudies ? undefined : 'text-muted pe-none'}
          >
            {studies.map((study) => {
              const patientStudy = patientStudies.find(
                (ps) => ps.studyId === study.id
              );

              const recommendedDate = recommendedStudyHistory.find(
                (item) => item.study.id === study.id
              )?.date;

              return (
                <tr key={study.id} className="mt-1 pb-2 align-middle">
                  <td>
                    {study.name}{' '}
                    {recommendedDate && (
                      <OverlayTrigger
                        placement="right-end"
                        overlay={
                          <Popover>
                            <PopoverHeader>Studies</PopoverHeader>
                            <PopoverBody className="p-2">
                              Recommended - {formatDateDisplay(recommendedDate)}
                            </PopoverBody>
                          </Popover>
                        }
                      >
                        <Badge pill>R</Badge>
                      </OverlayTrigger>
                    )}
                  </td>
                  <td className="text-center" style={{ width: '15%' }}>
                    {formatDateDisplay(patientStudy?.updatedAt) || <Dash />}
                  </td>
                  <td style={{ width: '25%' }}>
                    <StudyStatusDropdown
                      studyStatus={patientStudy?.studyStatus}
                      onChange={(updatedStatusId) =>
                        handleStudyStatusOnChange(
                          study.id,
                          updatedStatusId,
                          patientStudy?.id
                        )
                      }
                      disabled={
                        !Roles.canModifyRecords(userRole) || !showActiveStudies
                      }
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <EmptyTableMessage message="No studies found." />
      )}
      {!!modalValues.studyId && (
        <StudyStatusReasonModal
          studyId={modalValues.studyId}
          patientStudyId={modalValues.patientStudyId}
          studyStatusId={modalValues.studyStatusId}
          formFieldLabel={modalValues.formFieldLabel}
          onClose={() => setModalValues({})}
          onSubmit={handleStudyStatusChange}
          isSubmitting={updatePatientStudyStatus.isLoading}
        />
      )}
    </Stack>
  );
}

PatientStudiesSection.propTypes = {
  patientId: PropTypes.number.isRequired,
  patientStudies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      study: PropTypes.shape({
        id: PropTypes.number.isRequired,
        isRegistry: PropTypes.bool.isRequired,
      }).isRequired,
    })
  ).isRequired,
  siteStudies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      active: PropTypes.bool.isRequired,
      isRegistry: PropTypes.bool.isRequired,
      marketName: PropTypes.string,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};
