import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import NumericScoreCard from './NumericScoreCard';
import RiskScoreCard from './RiskScoreCard';
import { RISK_SCORE, NUMERIC_VALUE } from 'entities/IndicationValueTypes';
import { usePatient } from 'hooks/patientHooks';

export default function PatientIndicationsSection({ patientId }) {
  const { data: patient, isLoading } = usePatient(patientId);

  if (isLoading) return null;

  const indications = Object.groupBy(
    patient.patientIndications,
    ({ indication }) => indication.valueType
  );

  const shouldDisplayIndications = ['Risk Score', 'Numeric Value'].some(
    (valueType) => Object.keys(indications).includes(valueType)
  );

  return (
    <Stack>
      <div className="display-3">Indications</div>
      {shouldDisplayIndications ? (
        <Stack
          direction="horizontal"
          gap={3}
          className="py-3 flex-wrap align-items-stretch"
        >
          {indications[RISK_SCORE]?.map(({ indication, value }) => (
            <RiskScoreCard
              key={indication.id}
              name={indication.displayName}
              value={(value * 100).toFixed()}
            />
          ))}
          {indications[NUMERIC_VALUE]?.map(({ indication, value }) => (
            <NumericScoreCard
              key={indication.id}
              name={indication.displayName}
              value={value.toFixed(2)}
            />
          ))}
        </Stack>
      ) : (
        <Row className="py-3">
          <Col>No indications avaialble.</Col>
        </Row>
      )}
    </Stack>
  );
}

PatientIndicationsSection.propTypes = {
  patientId: PropTypes.number.isRequired,
};
