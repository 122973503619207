import { useAuth0 } from '@auth0/auth0-react';
import { DateTime } from 'luxon';
import React, { useContext, useState, useEffect } from 'react';
import {
  Button,
  Container,
  Navbar,
  Nav,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { BoxArrowRight, QuestionCircle } from 'react-bootstrap-icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { UserContext } from '../Provider/UserProvider';
import SupportRequestModal from '../SupportRequestModal';
import API from 'api';
import ConfirmationDialog from 'components/ConfirmationDialog';
import Roles from 'entities/Roles';
import paths from 'entities/SitePaths';
import { useRecentPatientMessages } from 'hooks/patientMessageHooks';
import { useCurrentUser } from 'hooks/userHooks';
import { hasItemsCreatedToday } from 'pages/utils/dateTimeUtils';
import useDashboardStore from 'stores/dashboardStore';

const MENU = {
  dashboard: paths.DASHBOARD,
  admin: paths.ADMIN,
  schedule: paths.SCHEDULE,
  search: paths.SEARCH,
  messages: paths.MESSAGES,
};

const startOfDay = DateTime.now().startOf('day').toJSDate();

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const [messageCount, setMessageCount] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const { userRole, fullName, organizationId } = useContext(UserContext);

  const { logout } = useAuth0();
  const resetDashboard = useDashboardStore((state) => state.resetDashboard);

  const currentUser = useCurrentUser();
  const patients = useRecentPatientMessages(startOfDay);

  const selectedHeader = `/${location.pathname.split('/')[1]}`;
  const showMyOrgDropdownItem = [
    Roles.ADMINISTRATOR,
    Roles.ORGANIZATION_OWNER,
  ].includes(userRole);

  const initials = fullName
    .split(' ')
    .map((name) => name[0])
    .join('');

  const handleSendResetPasswordEmailOnClick = () => {
    API.sendResetPasswordEmail(currentUser.data.userName);
    setShowConfirmation(false);
  };

  useEffect(() => {
    if (patients.data) {
      const patientIds = patients.data
        .filter((patient) => hasItemsCreatedToday(patient.interactions))
        .map((patient) => patient.id);

      setMessageCount(new Set(patientIds).size);
    }
  }, [patients.data, setMessageCount]);

  if (currentUser.isLoading) return null;

  return (
    <Navbar id="site-header" bg="white" className="d-block site-header">
      <Container fluid>
        <Navbar.Brand as="div">
          <Link to={MENU.dashboard}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/ObjectiveScreenLogo.png`}
              height="35px"
              alt="ObjectiveScreen"
            />
          </Link>
        </Navbar.Brand>
        <Nav className="navbar-nav align-items-center" as="ul">
          {userRole === Roles.ADMINISTRATOR && (
            <Nav.Item as="li">
              <Nav.Link
                className="display-4 fw-normal"
                onClick={() => navigate(MENU.admin)}
                active={selectedHeader === MENU.admin}
              >
                Admin
              </Nav.Link>
            </Nav.Item>
          )}
          <Nav.Item as="li">
            <Nav.Link
              className="display-4 fw-normal"
              onClick={() => {
                resetDashboard();
                navigate(MENU.dashboard);
              }}
              disabled={selectedHeader === MENU.dashboard}
              active={selectedHeader === MENU.dashboard}
            >
              Dashboard
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Nav.Link
              className="display-4 fw-normal"
              onClick={() => navigate(MENU.schedule)}
              disabled={selectedHeader === MENU.schedule}
              active={selectedHeader === MENU.schedule}
            >
              Schedule
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Nav.Link
              className="display-4 fw-normal"
              onClick={() => navigate(MENU.messages)}
              disabled={selectedHeader === MENU.messages}
              active={selectedHeader === MENU.messages}
            >
              Messages
              {messageCount > 0 && (
                <small
                  className="badge rounded-pill bg-danger"
                  style={{ position: 'relative', top: '-10px' }}
                >
                  {messageCount}
                </small>
              )}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Help</Tooltip>}
            >
              <Button
                className="border-0"
                variant=""
                onClick={() => setShowRequestModal(true)}
              >
                <QuestionCircle size={20} />
              </Button>
            </OverlayTrigger>
          </Nav.Item>
          <Nav.Item as="li" className="align-self-center">
            <Dropdown align="end">
              <Dropdown.Toggle
                variant=""
                id="user-dropdown"
                className="border-0"
              >
                <div className="rounded-circle p-1 display-4 fw-normal border border-info border-2">
                  {initials}
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {showMyOrgDropdownItem && (
                  <Dropdown.Item
                    as="div"
                    disabled={selectedHeader === paths.MY_ORGANIZATION}
                  >
                    {/* The Link component inside of Dropdown.Item allows the app to use React Router navigation */}
                    <Link
                      className={`display-4 ${
                        selectedHeader === paths.MY_ORGANIZATION
                          ? 'text-gray-300'
                          : 'text-info'
                      } text-decoration-none`}
                      to={`/organizations/${organizationId}`}
                    >
                      My Organization
                    </Link>
                  </Dropdown.Item>
                )}

                <Dropdown.Item
                  as="button"
                  className="display-4 text-info"
                  onClick={() => setShowConfirmation(true)}
                >
                  Change Password
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav.Item>
          <Nav.Item as="li">
            <Nav.Link
              onClick={() =>
                logout({ logoutParams: { returnTo: window.location.origin } })
              }
            >
              <BoxArrowRight size={20} />
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Container>
      <ConfirmationDialog
        show={showConfirmation}
        onConfirm={handleSendResetPasswordEmailOnClick}
        onCancel={() => setShowConfirmation(false)}
        title="Change Password"
        bodyText="Do you want to send a password reset email?"
      />
      {showRequestModal && (
        <SupportRequestModal onClose={() => setShowRequestModal(false)} />
      )}
    </Navbar>
  );
}

export default Header;
