import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../axios';
import StatusGroups from '../entities/StatusGroups';

export function usePatientEncounter(patientId, encounterId, draftEncounter) {
  let cacheKey = [patientId];
  const options = { enabled: Boolean(patientId) && Boolean(encounterId) };

  if (encounterId) {
    cacheKey = [patientId, encounterId];
  } else {
    options.initialData = draftEncounter;
    options.cacheTime = 0;
  }

  return useQuery(
    ['patientEncounter', ...cacheKey],
    () =>
      axios
        .get(`/api/patients/${patientId}/encounters/${encounterId}`)
        .then((res) => res.data),
    options
  );
}

const createPatientEncounter = async (encounter) => {
  const result = await axios.post(
    `/api/patients/${encounter.patientId}/encounters/`,
    encounter
  );
  return result.data;
};

export function useCreatePatientEncounter(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createPatientEncounter,
    onSuccess: (encounter) => {
      const { id: encounterId, patientId } = encounter;
      queryClient.invalidateQueries(['patient', patientId]);
      queryClient.invalidateQueries(['patientAppointments', { patientId }]);
      queryClient.invalidateQueries([
        'patientEncounter',
        patientId,
        encounterId,
      ]);
      queryClient.invalidateQueries(['patientMedications', patientId]);
      queryClient.invalidateQueries(['latestEncounterMedications', patientId]);
      queryClient.invalidateQueries(['patientObservations', { patientId }]);

      options?.onSuccess?.(encounter);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const updatePatientEncounter = async (encounter) => {
  const response = await axios.put(
    `/api/patients/${encounter.patientId}/encounters/`,
    encounter
  );

  return response.data;
};

export function useUpdatePatientEncounter(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updatePatientEncounter,
    onSuccess: (encounter) => {
      const { id: encounterId, patientId } = encounter;
      queryClient.invalidateQueries(['patient', patientId]);
      queryClient.invalidateQueries(['patientAppointments', { patientId }]);
      queryClient.invalidateQueries([
        'patientEncounter',
        patientId,
        encounterId,
      ]);
      queryClient.invalidateQueries(['patientMedications', patientId]);
      queryClient.invalidateQueries(['latestEncounterMedications', patientId]);
      queryClient.invalidateQueries(['patientObservations', { patientId }]);
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === 'patients' &&
          query.queryKey[1]?.statusGroup === StatusGroups.PRESCREEN,
      });

      options?.onSuccess?.(encounter);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const deletePatientEncounter = async (values) => {
  const { patientId, encounterId, cancellationReasonId } = values;
  await axios.delete(`/api/patients/${patientId}/encounters/${encounterId}`, {
    data: { cancellationReasonId },
  });
  return { patientId, encounterId };
};

export function useDeletePatientEncounter(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deletePatientEncounter,
    onSuccess: ({ patientId, encounterId }) => {
      queryClient.invalidateQueries([
        'patientEncounter',
        patientId,
        encounterId,
      ]);
      queryClient.invalidateQueries(['patientAppointments', { patientId }]);
      queryClient.invalidateQueries(['patientObservations', { patientId }]);
      queryClient.invalidateQueries(['patientMedications', patientId]);
      queryClient.invalidateQueries(['patient', patientId]);
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === 'patients' &&
          query.queryKey[1]?.statusGroup === StatusGroups.PRESCREEN,
      });

      options?.onSuccess?.({ patientId, encounterId });
    },
    onError: (error) => options?.onError?.(error),
  });
}
