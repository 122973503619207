import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../axios';

export function useRegistries(activeOnly = true) {
  const params = { activeOnly, isRegistry: true };
  return useQuery(['registries', params], () =>
    axios.get('/api/studies', { params }).then((res) => res.data)
  );
}

export function useStudies(activeOnly = true) {
  const params = { activeOnly, isRegistry: false };
  return useQuery(['studies', params], () =>
    axios.get('/api/studies', { params }).then((res) => res.data)
  );
}

export function useSearchStudies(substring = '', enabled = true) {
  const params = { substring };
  return useQuery(
    ['search-studies', substring],
    () => axios.get('/api/search-studies', { params }).then((res) => res.data),
    { keepPreviousData: true, enabled, placeholderData: [] }
  );
}

export function useStudy(studyId) {
  return useQuery(
    ['study', studyId],
    () => axios.get(`/api/studies/${studyId}`).then((res) => res.data),
    { enabled: !!studyId }
  );
}

export function useStudyMetrics(pageIndex, pageSize, query, filter) {
  return useQuery(['studyMetrics', pageIndex, pageSize, query, filter], () =>
    axios
      .get('/api/studies/metrics', {
        params: { pageIndex, pageSize, query, filter },
      })
      .then((res) => res.data)
  );
}

const createStudy = async (study) => {
  const result = await axios.post('/api/admin/studies', study);
  return result.data;
};

export function useCreateStudy(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createStudy,
    onSuccess: (study) => {
      queryClient.invalidateQueries(['studyMetrics']);
      queryClient.invalidateQueries(['studies']);

      options?.onSuccess?.(study);
    },
    onError: (error) => options?.onError?.(error),
  });
}
const updateStudy = async (study) => {
  const result = await axios.put(`/api/admin/studies`, study);
  return result.data;
};

export function useUpdateStudy(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateStudy,
    onSuccess: (study) => {
      queryClient.invalidateQueries(['studyMetrics']);
      queryClient.invalidateQueries(['admin-studies']);
      queryClient.invalidateQueries(['studies']);
      queryClient.invalidateQueries(['study', study.id]);
      queryClient.invalidateQueries(['patients']);
      queryClient.invalidateQueries(['patient']);

      options?.onSuccess?.(study);
    },
    onError: (error) => options?.onError?.(error),
  });
}
