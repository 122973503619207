import PropTypes from 'prop-types';
import React from 'react';
import { Col } from 'react-bootstrap';
import ChartReviewHistoryTable from './ChartReviewHistoryTable';
import PhoneCallHistoryTable from './PhoneCallHistoryTable';
import RecruitmentHistoryTable from './RecruitmentHistoryTable';
import StudyStatusHistoryTable from './StudyStatusHistoryTable';
import CollapsibleSection from '../../CollapsibleSection';

export default function HistoryTab({ patientContext }) {
  const historyTypes = [
    { name: 'Calls', component: PhoneCallHistoryTable },
    { name: 'Chart Review', component: ChartReviewHistoryTable },
    { name: 'Study History', component: StudyStatusHistoryTable },
    { name: 'Patient History', component: RecruitmentHistoryTable },
  ];

  return (
    <Col className="bg-white pt-2 pb-2 px-3 d-grid gap-1">
      {historyTypes.map((type, i) => {
        const Table = type.component;
        return (
          <CollapsibleSection
            key={type.name}
            title={type.name}
            isLast={i === historyTypes.length - 1}
          >
            <Table patientContext={patientContext} />
          </CollapsibleSection>
        );
      })}
    </Col>
  );
}

HistoryTab.propTypes = {
  patientContext: PropTypes.shape({
    patientId: PropTypes.number.isRequired,
    siteId: PropTypes.number.isRequired,
  }).isRequired,
};
