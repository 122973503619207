import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col, Dropdown, DropdownButton, ListGroup } from 'react-bootstrap';
import { Pencil } from 'react-bootstrap-icons';

import { getAddressDisplay } from 'pages/utils/addressUtils';
import { formatDateDisplay } from 'pages/utils/dateTimeUtils';
import { formatPhoneDisplay } from 'pages/utils/phoneUtils';

function SiteInfo({ site, setShowSiteModal }) {
  return (
    <>
      <Row>
        <Col>
          <div className="d-flex">
            <h2 className="flex-grow-1 fs-3 fw-normal mb-4">{site.name}</h2>
            <DropdownButton title="Actions">
              <Dropdown.Item onClick={() => setShowSiteModal(true)}>
                <Pencil /> Edit
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <ListGroup>
            <ListGroup.Item>
              <span className="fw-bold">Organization: </span>
              {site.organization?.name}
            </ListGroup.Item>
            <ListGroup.Item>
              <span className="fw-bold">Research Site Name: </span>
              {site.fullName}
            </ListGroup.Item>
            <ListGroup.Item>
              <span className="fw-bold">Address: </span>
              {getAddressDisplay(site)}
            </ListGroup.Item>
            <ListGroup.Item>
              <span className="fw-bold">Main Phone Number: </span>
              {formatPhoneDisplay(site.phone)}
            </ListGroup.Item>
            <ListGroup.Item>
              <span className="fw-bold">IANA Zone Name: </span>
              {site.zone?.name}
            </ListGroup.Item>
            <ListGroup.Item>
              <span className="fw-bold">Date Created: </span>
              {formatDateDisplay(site.createdAt)}
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
    </>
  );
}

SiteInfo.propTypes = {
  site: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postalCode: PropTypes.string,
    name: PropTypes.string,
    organization: PropTypes.shape({
      name: PropTypes.string,
    }),
    fullName: PropTypes.string,
    phone: PropTypes.string,
    zone: PropTypes.shape({
      name: PropTypes.string,
    }),
    createdAt: PropTypes.string,
  }).isRequired,
  setShowSiteModal: PropTypes.func.isRequired,
};

export default SiteInfo;
