import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useMemo,
  useState,
} from 'react';
import {
  NumberParam,
  NumericArrayParam,
  StringParam,
  useQueryParams,
} from 'use-query-params';
import { SCHEDULE_VIEW, EMPTY_APPOINTMENT } from '../constants';
import { UserContext, Roles } from 'components/Provider/UserProvider';
import AppointmentTypes from 'entities/AppointmentTypes';
import paths from 'entities/SitePaths';
import useAppointmentStatuses from 'hooks/appointmentStatusHooks';
import { useSiteAppointments } from 'hooks/siteAppointmentHooks';
import { useSites } from 'hooks/siteHooks';
import { useRegistries, useStudies } from 'hooks/studyHooks';
import { useStudyStatuses } from 'hooks/studyStatusHooks';
import { getDefaultInterval } from 'pages/utils/dateTimeUtils';

const ScheduleContext = createContext(null);

function ScheduleProvider({ children }) {
  const [queryParams, setQueryParams] = useQueryParams({
    siteId: NumberParam,
    siteTimeZone: StringParam,
    studyIds: NumericArrayParam,
    registryIds: NumericArrayParam,
    studyStatusIds: NumericArrayParam,
    appointmentStatusIds: NumericArrayParam,
    appointmentTypeId: NumberParam,
    startDate: StringParam,
    endDate: StringParam,
    providerName: StringParam,
    view: StringParam,
    pageIndex: NumberParam,
    pageSize: NumberParam,
    sortField: StringParam,
    sortDirection: StringParam,
  });

  const ref = useRef(queryParams);

  const [calendarEvents, setCalendarEvents] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const [showCancellationModal, setShowCancellationModal] = useState(false);

  const { userRole } = useContext(UserContext);
  const allowChanges = useRef(Roles.canModifyRecords(userRole)).current;

  const sites = useSites();
  const studies = useStudies();
  const registries = useRegistries();
  const studyStatuses = useStudyStatuses();
  const appointmentStatuses = useAppointmentStatuses();

  const appointments = useSiteAppointments(
    {
      siteId: queryParams.siteId,
      dateRange: [queryParams.startDate, queryParams.endDate],
      appointmentTypeId: queryParams.appointmentTypeId,
    },
    queryParams.view !== SCHEDULE_VIEW.LIST &&
      Boolean(queryParams.siteId) &&
      Boolean(queryParams.appointmentTypeId) &&
      Boolean(queryParams.startDate) &&
      Boolean(queryParams.endDate)
  );

  const handleSiteChange = useCallback(
    (siteId, resetInterval = false) => {
      if (ref.current?.siteId !== siteId) {
        const selectedSite = sites.data.find(
          (site) => site.id === Number(siteId)
        );

        const updatedState = {
          siteId: selectedSite.id,
          siteTimeZone: selectedSite.zone.name,
        };

        if (resetInterval) {
          const defaultInterval = getDefaultInterval(selectedSite.zone.name);
          updatedState.startDate = defaultInterval.start.toISO();
          updatedState.endDate = defaultInterval.end.toISO();
        }

        setQueryParams(updatedState, 'replaceIn');
      }
    },
    [ref, sites.data, setQueryParams]
  );

  useEffect(() => {
    setQueryParams(
      {
        studyIds: [],
        registryIds: [],
        studyStatusIds: [],
        appointmentStatusIds: [],
        appointmentTypeId: AppointmentTypes.OBJECTIVE_SCREEN,
        view: SCHEDULE_VIEW.WORK_WEEK,
        pageIndex: 0,
        pageSize: 25,
      },
      'replaceIn'
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // no site is selected, but there are sites available, pick the first one
    if (!queryParams.siteId && sites.data?.length > 0) {
      // eslint-disable-next-line no-use-before-define
      handleSiteChange(sites.data[0].id, true);
    }
  }, [sites.data, handleSiteChange, queryParams.siteId]);

  useEffect(() => {
    const zone = queryParams.siteTimeZone;

    if (appointments.data) {
      const mappedAppointments = appointments.data
        .filter(
          (a) => a.appointmentTypeId === Number(queryParams.appointmentTypeId)
        )
        .map((a) => {
          const apptDate = DateTime.fromISO(a.appointmentDate, {
            zone,
          }).setZone('local', {
            keepLocalTime: true,
          });

          return {
            id: a.id,
            start: apptDate.toJSDate(),
            end: apptDate.plus({ minutes: a.duration || 30 }).toJSDate(),
            reason: a.reason,
            fullName: a.patient.fullName,
            patientDetailUrl: `${paths.PATIENTS}/${a.patientId}`,
            tooltip: [
              a.patient.doNotContact ? 'DO NOT CONTACT' : null,
              apptDate.toLocaleString(DateTime.DATETIME_SHORT),
              a.patient.fullName,
              a.reason,
            ]
              .filter(Boolean)
              .join(' - '),
            appointmentTypeId: a.appointmentTypeId,
            canEdit: a.appointmentTypeId === AppointmentTypes.OBJECTIVE_SCREEN,
            doNotContact: a.patient.doNotContact,
          };
        });

      setCalendarEvents(mappedAppointments);
    }
  }, [
    appointments.data,
    queryParams.appointmentTypeId,
    queryParams.siteTimeZone,
  ]);

  const value = useMemo(
    () => ({
      allowChanges,
      sites: sites.data,
      studies: studies.data,
      registries: registries.data,
      studyStatuses: studyStatuses.data,
      appointmentStatuses: appointmentStatuses.data,
      stateValues: queryParams,
      calendarEvents,
      selectedAppointment,
      showAppointmentModal,
      showCancellationModal,
      handleSiteChange,
      handleApptTypeChange: (appointmentTypeId) => {
        setQueryParams({ appointmentTypeId }, 'replaceIn');
      },
      handleRangeChange: (range, view) => {
        let startDate;
        let endDate;

        const selectedView = view || queryParams.view;

        switch (selectedView) {
          case SCHEDULE_VIEW.DAY:
            startDate = DateTime.fromJSDate(range[0]).startOf('day').toISO();
            endDate = DateTime.fromJSDate(range[0]).endOf('day').toISO();
            break;
          case SCHEDULE_VIEW.WORK_WEEK:
            startDate = DateTime.fromJSDate(range[0]).startOf('day').toISO();
            endDate = DateTime.fromJSDate(range[range.length - 1])
              .endOf('day')
              .toISO();
            break;
          case SCHEDULE_VIEW.MONTH:
            startDate = DateTime.fromJSDate(range.start).startOf('day').toISO();
            endDate = DateTime.fromJSDate(range.end).endOf('day').toISO();
            break;
          case SCHEDULE_VIEW.LIST:
            startDate = DateTime.fromJSDate(range.start).toISO();
            endDate = DateTime.fromJSDate(range.end).toISO();
            break;
          default:
            break;
        }

        const updatedValues = { startDate, endDate, view: selectedView };

        setQueryParams(updatedValues, 'replaceIN');
      },
      handleAddAppointment: () => {
        if (allowChanges) {
          setSelectedAppointment(EMPTY_APPOINTMENT);
          setShowAppointmentModal(true);
        }
      },
      handleSelectEvent: (calendarEvent, event) => {
        if (calendarEvent.canEdit && event.target.nodeName !== 'A') {
          const appointment = appointments.data.find(
            (a) => a.id === calendarEvent.id
          );

          const appointmentDateStripped = DateTime.fromISO(
            appointment.appointmentDate,
            {
              zone: queryParams.siteTimeZone,
            }
          ).toISO({
            includeOffset: false,
          });

          const updatedAppointment = {
            id: appointment.id,
            encounter: appointment.encounter,
            encounterTypeId: appointment.encounterTypeId,
            patientId: appointment.patientId,
            patient: { fullName: appointment.patient.fullName },
            appointmentDate: appointmentDateStripped,
            duration: appointment.duration,
          };

          setSelectedAppointment(updatedAppointment);
          setShowAppointmentModal(true);
        }
      },
      handleProviderNameChange: (providerName) => {
        setQueryParams({ providerName }, 'replaceIn');
      },
      handleStudiesChange: (studyIds) => {
        setQueryParams({ studyIds }, 'replaceIn');
      },
      handleRegistriesChange: (registryIds) => {
        setQueryParams({ registryIds }, 'replaceIn');
      },
      handleStudyStatusesChange: (studyStatusIds) => {
        setQueryParams({ studyStatusIds }, 'replaceIn');
      },
      handleAppointmentStatusesChange: (appointmentStatusIds) => {
        setQueryParams({ appointmentStatusIds }, 'replaceIn');
      },
      handleCancelAppointment: () => {
        setShowAppointmentModal(false);
        setShowCancellationModal(true);
      },
      handleCloseAppointmentModal: () => {
        setShowAppointmentModal(false);
        setSelectedAppointment(null);
      },
      handleCloseCancellationModal: () => {
        setShowCancellationModal(false);
        setSelectedAppointment(null);
      },
      handlePaginationChange: (updater) => {
        const updated = updater({
          pageIndex: queryParams.pageIndex,
          pageSize: queryParams.pageSize,
        });

        setQueryParams(updated, 'replaceIn');
      },
      handleSortChange: (updater) => {
        const updated = updater([
          {
            id: queryParams.sortField,
            desc: queryParams.sortDirection === 'DESC',
          },
        ]);

        const { id, desc } = updated[0]; // multi-sort not supported, take the first one

        setQueryParams(
          {
            sortField: id,
            sortDirection: desc ? 'DESC' : 'ASC',
          },
          'replaceIn'
        );
      },
      getEventProps: (event) => {
        const classNames = [];

        switch (event.appointmentTypeId) {
          case 1:
            classNames.push('event-type-1');
            break;
          case 2:
            classNames.push('event-type-2');
            break;
          case 3:
            classNames.push('event-type-3');
            break;
          default:
            break;
        }

        if (event.doNotContact) {
          classNames.push('do-not-contact');
        }

        return { className: classNames.join(' ') };
      },
    }),
    [
      allowChanges,
      appointments.data,
      calendarEvents,
      selectedAppointment,
      setQueryParams,
      showAppointmentModal,
      showCancellationModal,
      sites.data,
      queryParams,
      studies.data,
      registries.data,
      studyStatuses.data,
      appointmentStatuses.data,
      setSelectedAppointment,
      setShowAppointmentModal,
      handleSiteChange,
    ]
  );

  if (
    [sites, studies, registries, studyStatuses, appointmentStatuses].some(
      (hook) => hook.isLoading
    )
  ) {
    return null;
  }

  return (
    <ScheduleContext.Provider value={value}>
      {children}
    </ScheduleContext.Provider>
  );
}

export default ScheduleProvider;
export { ScheduleContext, ScheduleProvider };

ScheduleProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
