import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { TelephoneOutbound } from 'react-bootstrap-icons';
import CallForm from './CallForm';
import PhoneNumberDisplay from './PhoneNumber';
import PatientInteractionSource from 'entities/PatientInteractionSource';
import { useCreatePatientInteraction } from 'hooks/patientInteractionHooks';

export default function CallPane({ patient, isReadOnly }) {
  const [error, setError] = useState(null);
  /** @type {React.MutableRefObject<HTMLFormElement>} */
  const formRef = useRef();

  const createPatientInteraction = useCreatePatientInteraction({
    onError: (e) => setError(e.response?.data?.message),
  });

  const handleClick = () => {
    formRef.current.dispatchEvent(
      new Event('submit', { bubbles: true, cancelable: true })
    );
  };

  const handleFormSubmit = (values, { resetForm }) => {
    setError('');

    const data = {
      patientId: patient.id,
      source: PatientInteractionSource.PhoneCall,
      type: values.callType,
      text: values.callNotes,
      durationMin: values.callDurationMin || 0,
      callOutcomeId: values.callOutcomeId,
    };

    createPatientInteraction.mutate(data, {
      onSuccess: () => resetForm(),
    });
  };

  return (
    <div className="d-flex flex-column">
      <Alert variant="danger" show={!!error}>
        {error}
      </Alert>
      <div className="d-grid gap-0" style={{ overflow: 'hidden auto' }}>
        <div className="px-4 mt-4 d-grid gap-2">
          <PhoneNumberDisplay
            label="Home Phone"
            icon={<TelephoneOutbound />}
            number={patient.homePhone}
            asLink={!isReadOnly}
          />
          <PhoneNumberDisplay
            label="Cell Phone"
            icon={<TelephoneOutbound />}
            number={patient.mobilePhone}
            asLink={!isReadOnly}
          />
        </div>
        <hr />
        <div className="px-4">
          <CallForm
            ref={formRef}
            handleFormSubmit={handleFormSubmit}
            handleSubmitClick={handleClick}
            disabled={
              isReadOnly ||
              createPatientInteraction.isLoading ||
              (!patient.mobilePhone && !patient.homePhone)
            }
            isBusy={createPatientInteraction.isLoading}
            siteId={patient.siteId}
          />
        </div>
      </div>
    </div>
  );
}

CallPane.propTypes = {
  patient: PropTypes.shape({
    id: PropTypes.number.isRequired,
    siteId: PropTypes.number.isRequired,
    mobilePhone: PropTypes.string,
    homePhone: PropTypes.string,
  }).isRequired,
  isReadOnly: PropTypes.bool.isRequired,
};
