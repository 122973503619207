import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Form, Button, Spinner, FormSelect } from 'react-bootstrap';
import * as yup from 'yup';
import LoadingIndicator from 'components/LoadingIndicator';
import PatientInteractionType from 'entities/PatientInteractionType';
import { useSiteCallOutcomes } from 'hooks/siteCallOutcomeHooks';

const MAX_CHARACTERS = 1000;

const initialValues = {
  callType: '',
  callOutcomeId: '',
  callNotes: '',
};

const schema = yup.object({
  callType: yup.string().required(),
  callOutcomeId: yup.number().required(),
  callNotes: yup.string().required().max(MAX_CHARACTERS),
});

/** @type {React.ForwardRefExoticComponent<any, any>} */
const CallForm = React.forwardRef(
  ({ handleFormSubmit, handleSubmitClick, disabled, isBusy, siteId }, ref) => {
    const { data: callOutcomes, isSuccess: callOutcomesSuccess } =
      useSiteCallOutcomes(siteId);

    if (!callOutcomesSuccess) {
      return <LoadingIndicator />;
    }

    return (
      <>
        <Formik
          validationSchema={schema}
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit} ref={ref}>
              <Form.Group controlId="formCallType">
                <Form.Label>Call Type</Form.Label>
                <FormSelect
                  name="callType"
                  value={values.callType}
                  onChange={handleChange}
                  isValid={touched.callType && !errors.callType}
                  isInvalid={!!errors.callType}
                  disabled={disabled}
                >
                  <option value="">Select Call Type</option>
                  <option value={PatientInteractionType.Inbound}>
                    Incoming Call
                  </option>
                  <option value={PatientInteractionType.Outbound}>
                    Outgoing Call
                  </option>
                </FormSelect>
              </Form.Group>
              <Form.Group controlId="formCallOutcomeId">
                <Form.Label>Call Outcome</Form.Label>
                <FormSelect
                  name="callOutcomeId"
                  value={values.callOutcomeId}
                  onChange={handleChange}
                  isValid={touched.callOutcomeId && !errors.callOutcomeId}
                  isInvalid={!!errors.callOutcomeId}
                  disabled={disabled}
                >
                  <option value="">Select Call Outcome</option>
                  {callOutcomes.map((outcome) => (
                    <option key={outcome.id} value={outcome.id}>
                      {outcome.name}
                    </option>
                  ))}
                </FormSelect>
              </Form.Group>
              <Form.Group controlId="formCallNotes">
                <Form.Label>Call Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  disabled={disabled}
                  rows={5}
                  type="text"
                  name="callNotes"
                  style={{ resize: 'none' }}
                  placeholder="Type your call notes here..."
                  value={values.callNotes}
                  onChange={handleChange}
                  isValid={touched.callNotes && !errors.callNotes}
                  isInvalid={!!errors.callNotes}
                />
              </Form.Group>
            </Form>
          )}
        </Formik>
        <Button
          variant="outline-primary"
          className="mt-2 float-end"
          onClick={() => handleSubmitClick()}
          disabled={disabled}
        >
          {isBusy ? (
            <Spinner
              animation="border"
              role="status"
              size="sm"
              className="ms-2"
            />
          ) : (
            'Save'
          )}
        </Button>
      </>
    );
  }
);

export default CallForm;

CallForm.propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
  handleSubmitClick: PropTypes.func.isRequired,
  isBusy: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  siteId: PropTypes.number.isRequired,
};
