import React from 'react';
import {
  Building,
  Hospital,
  ClipboardData,
  Person,
  SearchHeart,
  CardList,
} from 'react-bootstrap-icons';

const AdminMenu = Object.freeze({
  organizations: {
    title: (
      <>
        <Building /> Organizations
      </>
    ),
    description: 'Add, update, and manage Organizations',
    pathname: '/admin/organizations',
  },
  sites: {
    title: (
      <>
        <Hospital /> Sites
      </>
    ),
    description: 'Add, update, and manage Sites',
    pathname: '/admin/sites',
  },
  studies: {
    title: (
      <>
        <ClipboardData /> Studies
      </>
    ),
    description: 'Add, update, and manage Studies',
    pathname: '/admin/studies',
  },
  indications: {
    title: (
      <>
        <ClipboardData /> Indications
      </>
    ),
    description: 'Add, update, and manage Indications',
    pathname: '/admin/indications',
  },
  users: {
    title: (
      <>
        <Person /> Users
      </>
    ),
    description: 'Add, update, and manage Users',
    pathname: '/admin/users',
  },
  reasons: {
    title: (
      <>
        <CardList /> Reasons
      </>
    ),
    description: 'Add, update, and manage Reasons',
    pathname: '/admin/reasons',
  },
  studyMatching: {
    title: (
      <>
        <SearchHeart /> Study Matching
      </>
    ),
    description: 'Configure study matching queries',
    pathname: '/admin/study-matching',
  },
});

export default AdminMenu;
