import Akero105PrescreenForm from 'components/Encounter/Akero105PrescreenForm';
import NashPrescreenForm from 'components/Encounter/NashPrescreenForm';
import EncounterTypes from 'entities/EncounterTypes';

export default function getEncounterConfiguration(
  patientId,
  siteId,
  encounterType,
  encounterStatus
) {
  const encounter = {
    id: 0,
    patientId,
    startDate: new Date().toISOString(),
    encounterTypeId: encounterType.id,
    type: { name: encounterType.name },
    encounterStatusId: encounterStatus.id,
    medications: [],
    observations: [],
  };

  switch (encounterType.name) {
    case EncounterTypes.FIBROSCAN_PRESCREEN_VISIT:
      return {
        formComponent: NashPrescreenForm,
        encounterTemplate: encounter,
        initialFormValues: { ...NashPrescreenForm.initialValues, siteId },
        formSchema: NashPrescreenForm.schema,
      };
    case EncounterTypes.AKERO_105_PRESCREEN_VISIT:
      return {
        formComponent: Akero105PrescreenForm,
        encounterTemplate: encounter,
        initialFormValues: { ...Akero105PrescreenForm.initialValues, siteId },
        formSchema: Akero105PrescreenForm.schema,
      };
    default:
      throw Error(`Unknown encounter type: ${encounterType.name}`);
  }
}
