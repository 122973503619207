/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo, useState, useContext } from 'react';
import {
  TelephoneInbound,
  TelephoneOutbound,
  Dash,
} from 'react-bootstrap-icons';
import CallModal from './CallModal';
import {
  ACTION_BUTTON_TYPES,
  ActionButton,
} from 'components/Button/ActionButton';
import ConfirmationDialog from 'components/ConfirmationDialog';
import TableLayout from 'components/Layouts/TableLayout';
import LoadingIndicator from 'components/LoadingIndicator';
import { UserContext, Roles } from 'components/Provider/UserProvider';
import PatientInteractionSource from 'entities/PatientInteractionSource';
import SortDirection from 'entities/SortDirection';
import {
  usePatientInteractions,
  useDeletePatientInteraction,
} from 'hooks/patientInteractionHooks';
import { formatDateDisplay } from 'pages/utils/dateTimeUtils';

function PhoneCallHistoryTable({ patientContext }) {
  const { patientId, siteId } = patientContext;

  const { data, isLoading } = usePatientInteractions(
    patientId,
    PatientInteractionSource.PhoneCall,
    SortDirection.DESC
  );
  const [showConfirm, setShowConfirm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedPatientInteraction, setSelectedPatientInteraction] =
    useState(null);
  const { userRole } = useContext(UserContext);

  const deletePatientInteraction = useDeletePatientInteraction({
    onSuccess: () => {
      setSelectedPatientInteraction(null);
      setShowConfirm(false);
    },
  });

  const confirmDelete = (patientInteraction) => {
    setSelectedPatientInteraction(patientInteraction);
    setShowConfirm(true);
  };

  const deleteConfirmed = () =>
    deletePatientInteraction.mutate({
      patientId: selectedPatientInteraction.patientId,
      id: selectedPatientInteraction.id,
    });

  const editPatientInteraction = (patientInteraction) => {
    setSelectedPatientInteraction(patientInteraction);
    setShowModal(true);
  };

  const columns = useMemo(() => {
    const config = [
      {
        accessorKey: 'type',
        header: 'TYPE',
        meta: {
          headerProps: { className: 'text-center', style: { width: '80px' } },
          cellProps: { className: 'text-center' },
        },
        cell: (props) =>
          props.getValue() === 'Inbound' ? (
            <TelephoneInbound />
          ) : (
            <TelephoneOutbound />
          ),
      },
      {
        accessorKey: 'createdAt',
        header: 'DATE',
        meta: {
          headerProps: { className: 'text-center' },
          cellProps: { className: 'text-center text-nowrap' },
        },
        cell: (props) => formatDateDisplay(props.getValue()),
      },
      {
        id: 'callOutcome.name',
        accessorFn: (row) => row.callOutcome?.name,
        header: 'OUTCOME',
        cell: (props) => props.getValue() ?? <Dash />,
      },
      {
        accessorKey: 'text',
        header: 'NOTE',
      },
      {
        id: 'createdBy.fullName',
        accessorFn: (row) => row.createdBy?.fullName,
        header: 'USER',
        meta: { cellProps: { className: 'text-nowrap' } },
        cell: (props) => props.getValue() ?? <Dash />,
      },
      {
        header: 'ACTIONS',
        meta: {
          headerProps: { className: 'text-center', style: { width: '100px' } },
          cellProps: { className: 'text-center small' },
        },
        cell: (props) => (
          <>
            {!props.row.original.external &&
              Roles.canModifyRecords(userRole) && (
                <ActionButton
                  onClick={() => editPatientInteraction(props.row.original)}
                  action={ACTION_BUTTON_TYPES.EDIT}
                />
              )}
            {Roles.canModifyRecords(userRole) && (
              <ActionButton
                action={ACTION_BUTTON_TYPES.DELETE}
                onClick={() => confirmDelete(props.row.original)}
              />
            )}
          </>
        ),
      },
    ];

    return config;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <TableLayout
        columns={columns}
        data={data}
        noResultsText="0 calls found."
      />
      <CallModal
        interaction={selectedPatientInteraction ?? {}}
        show={showModal}
        onClose={() => setShowModal(false)}
        siteId={siteId}
      />
      <ConfirmationDialog
        show={showConfirm}
        title="Delete Call"
        bodyText="Are you sure you want to delete this call from the call history?"
        confirmText="Delete"
        onConfirm={deleteConfirmed}
        onCancel={() => setShowConfirm(false)}
      />
    </>
  );
}

export default PhoneCallHistoryTable;
