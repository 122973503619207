import { useQuery } from '@tanstack/react-query';
import axios from '../axios';

export function useGenders() {
  return useQuery(['genders'], () =>
    axios.get('/api/genders').then((res) => res.data)
  );
}

export function useEthnicGroups() {
  return useQuery(['ethnic-groups'], () =>
    axios.get('/api/ethnic-groups').then((res) => res.data)
  );
}

export function useRaces() {
  return useQuery(['races'], () =>
    axios.get('/api/races').then((res) => res.data)
  );
}
