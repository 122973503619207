import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Alert, Button, Form, Modal, Spinner, Row } from 'react-bootstrap';
import * as yup from 'yup';

import TextField from '../../../components/FormikForm/TextField';

import {
  useCreateSiteDataSource,
  useUpdateSiteDatSource,
} from 'hooks/siteDataSourceHooks';

const schema = yup.object({
  sourceId: yup.string().required(),
  sourceName: yup.string().required(),
  destinationId: yup.string().required(),
  destinationName: yup.string().required(),
  medicalRecordNumberIdentifier: yup.string().required(),
  clinicalSummarySourceId: yup.string().nullable(),
  clinicalSummarySourceName: yup.string().nullable(),
});

export default function SiteDataSourceModal({
  dataSource = { id: 0 },
  siteId,
  onClose,
}) {
  const formRef = useRef();

  const [appError, setAppError] = useState();

  const handleDataSourceSaved = () => {
    onClose();
  };

  const handleOnSubmitButtonClick = () => {
    setAppError();
    formRef.current.dispatchEvent(
      new Event('submit', { bubbles: true, cancelable: true })
    );
  };

  const createSiteDataSource = useCreateSiteDataSource({
    onSuccess: () => handleDataSourceSaved(),
    onError: (e) =>
      setAppError({
        name: e.response?.data?.name,
        message: e.response?.data?.message,
      }),
  });

  const updateSiteDataSource = useUpdateSiteDatSource({
    onSuccess: () => {
      handleDataSourceSaved();
    },
    onError: (e) =>
      setAppError({
        name: e.response?.data?.name,
        message: e.response?.data?.message,
      }),
  });

  const handleOnFormSubmit = (values) => {
    const dataSourceValues = {
      ...dataSource,
      ...values,
      siteId,
    };

    if (dataSourceValues.id === 0) {
      createSiteDataSource.mutate(dataSourceValues);
    } else {
      updateSiteDataSource.mutate(dataSourceValues);
    }
  };

  const isSubmitting =
    createSiteDataSource.isLoading || updateSiteDataSource.isLoading;

  return (
    <Modal
      show
      size="lg"
      backdrop="static"
      keyboard={false}
      centered
      onHide={onClose}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Data Source</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="danger" show={!!appError}>
          {appError?.message}
        </Alert>
        <Formik
          validationSchema={schema}
          onSubmit={handleOnFormSubmit}
          initialValues={{ ...dataSource }}
        >
          {({ handleSubmit }) => (
            <Form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
              ref={formRef}
            >
              <Row xs={2} className="gy-3">
                <TextField focus label="Source ID" name="sourceId" />
                <TextField label="Source Name" name="sourceName" />
                <TextField label="Destination ID" name="destinationId" />
                <TextField label="Destination Name" name="destinationName" />
                <TextField
                  label="MRN Source ID"
                  name="medicalRecordNumberIdentifier"
                  controlClassName="w-100"
                />
                <TextField
                  label="Clinical Summary ID"
                  name="clinicalSummarySourceId"
                />
                <TextField
                  label="Clinical Summary Name"
                  name="clinicalSummarySourceName"
                />
              </Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={handleOnSubmitButtonClick}
          disabled={isSubmitting}
        >
          {dataSource.id === 0 ? 'Create' : 'Save'}
          <Spinner
            animation="border"
            role="status"
            size="sm"
            className={`ms-2 ${isSubmitting ? '' : 'd-none'}`}
          />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

SiteDataSourceModal.propTypes = {
  dataSource: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired,
  siteId: PropTypes.number.isRequired,
};

SiteDataSourceModal.defaultProps = {
  dataSource: { id: 0 },
};
