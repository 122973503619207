import { Formik } from 'formik';
import { DateTime, Interval } from 'luxon';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, Spinner, Stack } from 'react-bootstrap';
import * as yup from 'yup';

import AppointmentDescription from './AppointmentDescription';
import AppointmentSlots from './AppointmentSlots';
import EncounterDurationSelect from './EncounterDurationSelect';
import EncounterTypeSelect from './EncounterTypeSelect';
import SelectField from 'components/FormikForm/SelectField';
import TextField from 'components/FormikForm/TextField';
import LoadingIndicator from 'components/LoadingIndicator';
import AppointmentTypes from 'entities/AppointmentTypes';
import { useCreatePatientAppointment } from 'hooks/patientAppointmentHooks';
import './SchedulePane.scss';
import { useSiteAppointments } from 'hooks/siteAppointmentHooks';
import { useSites } from 'hooks/siteHooks';

const schema = yup.object({
  encounterTypeId: yup.number().required(),
  siteId: yup.number().required(),
  appointmentDate: yup.string().required(),
  duration: yup.number().required(),
  appointmentTime: yup.date().required(),
});

export default function SchedulePane({ patient, isReadOnly }) {
  const timeZone = patient.site.zone.name;
  const formRef = useRef(null);

  const openHour = 7;
  const openMinute = 0;
  const closeHour = 17;
  const closeMinute = 0;
  const start = DateTime.fromObject(
    { hour: openHour, minute: openMinute },
    { zone: timeZone }
  );
  const end = DateTime.fromObject(
    { hour: closeHour, minute: closeMinute },
    { zone: timeZone }
  );

  const [selectedEncounterType, setSelectedEncounterType] = useState(null);
  const [selectedDate, setSelectedDate] = useState(
    start.toFormat('yyyy-MM-dd')
  );
  const [selectedDuration, setSelectedDuration] = useState(30);
  const [selectedTime, setSelectedTime] = useState(null);
  const [appError, setAppError] = useState(null);

  const [appointmentDisplay, setAppointmentDisplay] = useState(null);
  const [enableQuery, setEnableQuery] = useState(false);

  const [filter, setFilter] = useState({
    siteId: patient.siteId,
    dateRange: [start.toJSDate(), end.toJSDate()],
    appointmentTypeId: AppointmentTypes.OBJECTIVE_SCREEN,
    encounterTypeId: selectedEncounterType ? selectedEncounterType.id : null,
  });

  const { data: patientAppointments, isLoading } = useSiteAppointments(
    filter,
    enableQuery
  );

  const sites = useSites();

  const createPatientAppointment = useCreatePatientAppointment({
    onError: (e) => setAppError(e.response?.data?.message),
  });

  useEffect(() => {
    if (!selectedEncounterType) {
      setEnableQuery(false);
    } else {
      setFilter({ ...filter, encounterTypeId: selectedEncounterType.id });
      setEnableQuery(true);
    }

    setSelectedDate(start.toFormat('yyyy-MM-dd'));
    setSelectedTime();
    setSelectedDuration(30);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEncounterType]);

  useEffect(() => {
    const [year, month, day] = selectedDate.split('-');

    const newStart = DateTime.fromObject(
      {
        year: Number(year),
        month: Number(month),
        day: Number(day),
        hour: openHour,
        minute: openMinute,
      },
      { zone: timeZone }
    ).toJSDate();

    const newEnd = DateTime.fromObject(
      {
        year: Number(year),
        month: Number(month),
        day: Number(day),
        hour: closeHour,
        minute: closeMinute,
      },
      { zone: timeZone }
    ).toJSDate();

    setFilter({ ...filter, dateRange: [newStart, newEnd] });

    setSelectedTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  useEffect(() => {
    if (!selectedDate || !selectedTime) {
      setAppointmentDisplay();
    } else {
      const [year, month, day] = selectedDate.split('-');
      const { hour } = DateTime.fromJSDate(selectedTime);
      const { minute } = DateTime.fromJSDate(selectedTime);

      const appointmentDate = DateTime.fromObject(
        {
          year: Number(year),
          month: Number(month),
          day: Number(day),
          hour,
          minute,
        },
        { zone: timeZone }
      );

      setAppointmentDisplay(
        appointmentDate.toLocaleString(DateTime.DATETIME_HUGE)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, selectedTime]);

  useEffect(() => {
    setAppError();
  }, [selectedEncounterType, selectedDate, selectedDuration, selectedTime]);

  const initialValues = {
    encounterTypeId: '',
    siteId: patient.siteId,
    appointmentDate: selectedDate,
    duration: selectedDuration,
    appointmentTime: '',
  };

  const getAppointmentTimes = (duration) => {
    if (!patientAppointments || !enableQuery) return [];

    const existingAppointments = patientAppointments.map((x) => {
      const apptDate = DateTime.fromISO(x.appointmentDate, {
        zone: timeZone,
      }).setZone('local', {
        keepLocalTime: true,
      });

      return Interval.fromDateTimes(
        apptDate,
        apptDate.plus({ minutes: x.duration })
      );
    });

    const [year, month, day] = selectedDate.split('-');

    const slotStart = DateTime.fromObject({
      year: Number(year),
      month: Number(month),
      day: Number(day),
      hour: openHour,
      minute: openMinute,
    }).toLocal();
    const slotEnd = DateTime.fromObject({
      year: Number(year),
      month: Number(month),
      day: Number(day),
      hour: closeHour,
      minute: closeMinute,
    }).toLocal();

    const allSlots = Interval.fromDateTimes(slotStart, slotEnd).splitBy({
      minutes: 15,
    });

    const appointmentTimes = allSlots.map((d) => {
      const apptInterval = Interval.fromDateTimes(
        d.start,
        d.start.plus({ minutes: duration })
      );
      const isOverlapping = existingAppointments.some((y) =>
        apptInterval.overlaps(y)
      );
      return { date: d.start.toJSDate(), conflict: isOverlapping };
    });

    return appointmentTimes;
  };

  const handleSaveClick = async () => {
    setAppError();
    formRef.current.dispatchEvent(
      new Event('submit', { bubbles: true, cancelable: true })
    );
  };

  const handleFormSubmit = async (values, { resetForm }) => {
    const [year, month, day] = selectedDate.split('-');
    const appointmentDate = DateTime.fromObject(
      {
        year: Number(year),
        month: Number(month),
        day: Number(day),
        hour: DateTime.fromJSDate(selectedTime).hour,
        minute: DateTime.fromJSDate(selectedTime).minute,
      },
      { zone: timeZone }
    );

    const patientData = {
      patientId: patient.id,
      appointmentTypeId: AppointmentTypes.OBJECTIVE_SCREEN,
      encounterTypeId: selectedEncounterType ? selectedEncounterType.id : null,
      appointmentDate: appointmentDate.toJSDate(),
      reason: selectedEncounterType ? selectedEncounterType.name : null,
      duration: selectedDuration,
      siteId: values.siteId,
    };

    createPatientAppointment.mutate(patientData, {
      onSuccess: () => {
        resetForm();
        setSelectedEncounterType();
      },
    });
  };

  const handleCancelClick = async (resetForm) => {
    setSelectedEncounterType();
    resetForm();
  };

  if (sites.isLoading) return <LoadingIndicator />;

  return (
    <div className="mt-3">
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
      >
        {({ handleSubmit, handleChange, resetForm, values, setFieldValue }) => (
          <Form noValidate onSubmit={handleSubmit} ref={formRef}>
            <div
              className="d-flex flex-column"
              style={{ height: 'calc(100vh - 150px)' }}
            >
              <Stack className="px-4 mb-3" gap={3}>
                <EncounterTypeSelect
                  name="encounterTypeId"
                  disabled={isReadOnly}
                  value={values.encounterTypeId}
                  onChange={async (event, encounterType) => {
                    resetForm();
                    handleChange(event);
                    setSelectedEncounterType(encounterType);
                    await setFieldValue('appointmentTime', '');
                  }}
                />
                <span className="d-none">
                  <SelectField
                    controlId="formSite"
                    label={null}
                    name="siteId"
                    defaultDisplay="Select Site"
                    options={sites.data}
                    valueAccessor="id"
                    displayAccessor="name"
                    additionalOnChange={(e) => {
                      setFilter({ ...filter, siteId: e.target.value });
                    }}
                  />
                </span>

                <TextField
                  type="date"
                  name="appointmentDate"
                  value={values.appointmentDate}
                  disabled={isReadOnly || !selectedEncounterType}
                  additionalOnChange={async (e) => {
                    if (e.target.value !== '') {
                      setSelectedDate(e.target.value);
                      await setFieldValue('appointmentTime', '');
                    }
                  }}
                />

                <EncounterDurationSelect
                  name="duration"
                  value={Number(values.duration)}
                  disabled={isReadOnly || !selectedEncounterType}
                  onChange={(event) => {
                    handleChange(event);
                    setSelectedDuration(Number(event.target.value));
                  }}
                />
              </Stack>
              <div
                className="flex-fill border-top border-bottom"
                style={{ overflow: 'hidden auto' }}
              >
                {selectedEncounterType && (
                  <AppointmentSlots
                    value={selectedTime}
                    data={getAppointmentTimes(selectedDuration)}
                    onChange={async (value) => {
                      await setFieldValue('appointmentTime', value);
                      setSelectedTime(value);
                    }}
                    isLoading={isLoading}
                  />
                )}
              </div>
              <div className="mt-3">
                <AppointmentDescription
                  encounterType={selectedEncounterType}
                  appointmentDisplay={appointmentDisplay}
                  errorText={appError}
                />
              </div>
              <div className="mt-3 px-4 text-end">
                <Button
                  variant="outline-secondary"
                  onClick={() => handleCancelClick(resetForm)}
                  disabled={isReadOnly || createPatientAppointment.isLoading}
                >
                  Cancel
                </Button>
                <Button
                  variant="outline-primary"
                  className="ms-2"
                  onClick={handleSaveClick}
                  disabled={isReadOnly || createPatientAppointment.isLoading}
                >
                  {createPatientAppointment.isLoading ? (
                    <Spinner
                      animation="border"
                      role="status"
                      size="sm"
                      className="ms-2"
                    />
                  ) : (
                    'Save'
                  )}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

SchedulePane.propTypes = {
  patient: PropTypes.shape({
    id: PropTypes.number.isRequired,
    siteId: PropTypes.number.isRequired,
    site: PropTypes.shape({
      zone: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
  isReadOnly: PropTypes.bool.isRequired,
};
