import React, { useEffect, useState } from 'react';
import { Row, Col, Tab, Tabs } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import {
  BooleanParam,
  JsonParam,
  NumberParam,
  NumericArrayParam,
  StringParam,
  useQueryParams,
} from 'use-query-params';
import CriteriaTab from './components/CriteriaTab';
import ResultsTab from './components/ResultsTab';
import NavigateBack from 'components/Button/NavigateBack';
import LoadingIndicator from 'components/LoadingIndicator';
import paths from 'entities/SitePaths';
import { useFeasibilitySearch } from 'hooks/searchHooks';
import './index.scss';

const TABS = {
  CRITERIA: 'criteria',
  RESULTS: 'results',
};

const DEFAULT_PAGINATION = { pageIndex: 0, pageSize: 50 };
const DEFAULT_SORTING = [{ id: 'site.name', desc: false }];

export default function FeasibilitySearch() {
  const [query, setQuery] = useQueryParams({
    registry: NumberParam,
    minAge: NumberParam,
    maxAge: NumberParam,
    genders: NumericArrayParam,
    ethnicGroups: NumericArrayParam,
    city: StringParam,
    races: NumericArrayParam,
    postalCode: StringParam,
    siteIds: NumericArrayParam,
    showDoNotContact: BooleanParam,
    studies: NumericArrayParam,
    indications: JsonParam,
    studyStatuses: NumericArrayParam,
    minIndicationValue: NumberParam,
    maxIndicationValue: NumberParam,
    observations: JsonParam,
    diagnoses: JsonParam,
    pagination: JsonParam,
    sorting: JsonParam,
  });

  const [results, setResults] = useState();
  const [activeTab, setActiveTab] = useState(TABS.CRITERIA);

  const {
    mutate: search,
    isLoading,
    error,
  } = useFeasibilitySearch({
    onSuccess: (response) => {
      setActiveTab('results');
      setResults(response.data);
    },
    onError: () => {
      setActiveTab('results');
    },
  });

  const handleSearch = (resetPaginationAndSorting) => {
    if (resetPaginationAndSorting) {
      setQuery(
        {
          pagination: DEFAULT_PAGINATION,
          sorting: DEFAULT_SORTING,
        },
        'replaceIn'
      );
    }

    search({
      ...query,
    });
  };

  const { pagination, sorting } = query;

  const handleCriteriaChange = (criteria) => {
    setQuery(criteria, 'replaceIn');
  };

  const handleClearAll = () => {
    setQuery({}, 'replace');
  };

  const navigate = useNavigate();

  const onNavigateBack = () => {
    navigate(paths.DASHBOARD);
  };

  useEffect(() => {
    if (!Object.values(query).filter((v) => !!v).length) return;

    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.pagination, query.sorting]);

  // if user comes to feasability page with a prefilled URl,
  // execute search
  useEffect(() => {
    if (Object.values(query).filter((v) => !!v).length) handleSearch(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row
      id="feasibility-search"
      className="bg-light-gray h-100 overflow-scroll"
    >
      <Col>
        <Row className="mt-3 align-items-center">
          <Col className="px-0" xs={{ span: 2 }}>
            <NavigateBack onClick={onNavigateBack} text="DASHBOARD" />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <h3 className="display-3 text-info">Feasibility Search</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
              <Tab
                eventKey={TABS.CRITERIA}
                transition={false}
                title="CRITERIA"
                onClick={() => setActiveTab(TABS.CRITERIA)}
                className="border border-top-0 border-bottom-0"
                style={{ '--bs-border-radius': '0' }}
              >
                <div className="px-3 py-3">
                  <CriteriaTab
                    isLoading={isLoading}
                    onSearch={handleSearch}
                    searchCriteria={query}
                    onClearAll={handleClearAll}
                    onCriteriaChange={handleCriteriaChange}
                  />
                </div>
              </Tab>
              <Tab
                eventKey={TABS.RESULTS}
                transition={false}
                title="RESULTS"
                onClick={() => setActiveTab(TABS.RESULTS)}
                className="border border-top-0 border-bottom-0"
                style={{ '--bs-border-radius': '0' }}
              >
                <div className="px-3 py-3">
                  <h4 className="display-4 text-info">Results</h4>
                  <div>{error && 'Unable to perform search.'}</div>
                  {isLoading && <LoadingIndicator />}
                  {!isLoading &&
                    !error &&
                    Object.values(query).filter((v) => !!v).length && (
                      <ResultsTab
                        results={results}
                        pagination={pagination}
                        setPagination={(onChange) => {
                          const newPagination = onChange(pagination);
                          setQuery({ pagination: newPagination }, 'replaceIn');
                        }}
                        sorting={sorting}
                        setSorting={(onChange) => {
                          const newSorting = onChange(sorting);
                          setQuery({ sorting: newSorting }, 'replaceIn');
                        }}
                      />
                    )}
                </div>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
