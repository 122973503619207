import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useRef, useState, useEffect } from 'react';
import {
  Alert,
  Button,
  Form,
  Row,
  Col,
  Modal,
  Spinner,
  FormSelect,
} from 'react-bootstrap';
import * as yup from 'yup';
import LoadingIndicator from 'components/LoadingIndicator';
import { useUpdatePatientInteraction } from 'hooks/patientInteractionHooks';
import { useSiteCallOutcomes } from 'hooks/siteCallOutcomeHooks';

const MAX_CHARACTERS = 1000;

const schema = yup.object({
  callOutcomeId: yup.number().required(),
  text: yup.string().required().max(MAX_CHARACTERS),
});

export default function CallModal({ interaction, show, onClose, siteId }) {
  const formRef = useRef();
  const [error, setError] = useState('');
  const { data: callOutcomes, isSuccess: callOutcomesSuccess } =
    useSiteCallOutcomes(siteId);

  const updatePatientInteraction = useUpdatePatientInteraction({
    onSuccess: () => onClose(),
    onError: (err) => setError(err.response?.data?.message),
  });

  useEffect(() => {
    setError('');
  }, [show]);

  if (!callOutcomesSuccess) {
    return <LoadingIndicator />;
  }

  const handleCloseModal = () => {
    onClose();
  };

  const handleFormSubmit = (values) => {
    setError('');

    updatePatientInteraction.mutate(values);
  };

  const handleSave = () => {
    formRef.current.dispatchEvent(
      new Event('submit', { bubbles: true, cancelable: true })
    );
  };

  return (
    <Modal
      show={show}
      backdrop="static"
      keyboard={false}
      centered
      onHide={handleCloseModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>Note</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="danger" show={!!error}>
          {error}
        </Alert>

        <Formik
          validationSchema={schema}
          onSubmit={handleFormSubmit}
          initialValues={interaction}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit} ref={formRef}>
              <Row>
                <Col>
                  <Form.Group controlId="formCallOutcomeId" className="mb-3">
                    <Form.Label>Call Outcome</Form.Label>
                    <FormSelect
                      autoFocus
                      name="callOutcomeId"
                      value={values.callOutcomeId}
                      onChange={handleChange}
                      isValid={touched.callOutcomeId && !errors.callOutcomeId}
                      isInvalid={!!errors.callOutcomeId}
                    >
                      <option value="">Select Call Outcome</option>
                      {callOutcomes.map((outcome) => (
                        <option key={outcome.id} value={outcome.id}>
                          {outcome.name}
                        </option>
                      ))}
                    </FormSelect>
                  </Form.Group>
                  <Form.Group controlId="formText" className="mb-1">
                    <Form.Control
                      as="textarea"
                      rows={5}
                      name="text"
                      placeholder="Enter Note"
                      value={values.text}
                      onChange={handleChange}
                      isValid={touched.text && !errors.text}
                      isInvalid={!!errors.text}
                    />
                  </Form.Group>
                  <div className="text-end">{`${values.text.length}/${MAX_CHARACTERS} characters`}</div>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={handleSave}
          disabled={updatePatientInteraction.isLoading}
        >
          Save
          <Spinner
            animation="border"
            role="status"
            size="sm"
            className={`ms-2 ${
              updatePatientInteraction.isLoading ? '' : 'd-none'
            }`}
          />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

CallModal.propTypes = {
  interaction: PropTypes.shape({}).isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  siteId: PropTypes.number.isRequired,
};
