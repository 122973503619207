import React, { useEffect } from 'react';
import { Badge, Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import PatientSearch from './PatientSearch';
import { useSites } from 'hooks/siteHooks';
import useDashboardStore from 'stores/dashboardStore';

export default function DashboardToolbar() {
  const { site, study, updateSite, updateStudy } = useDashboardStore(
    (state) => state.toolbar
  );

  const sites = useSites();

  const navigate = useNavigate();

  const updateSelectedSite = (newSiteId) => {
    const newSite = sites.data.find((x) => x.id === newSiteId);
    updateSite(newSite);
  };

  useEffect(() => {
    if (sites.data?.length > 0 && !site) {
      updateSite(sites.data[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sites.data]);

  if (!site) return null;

  const handleSiteChange = (event) => {
    const updatedSiteId = Number(event.target.value);
    updateSelectedSite(updatedSiteId);
  };

  const handleStudyChange = (event) => {
    const newStudy = site.studies?.find(
      (s) => s.id === Number(event.target.value)
    );
    updateStudy(newStudy);
  };

  if (sites.isLoading) return null;

  return (
    <Row className="mt-3">
      <Col md={7} className="px-0">
        <Row>
          <Col xs={6} className="d-inline-flex align-items-baseline">
            <select
              className="form-select"
              onChange={handleSiteChange}
              value={site.id}
            >
              {sites.data.map((s) => (
                <option key={s.id} value={s.id.toString()}>
                  {s.name}
                </option>
              ))}
            </select>
          </Col>
          <Col xs={6} className="d-inline-flex align-items-baseline">
            <select
              className={
                site.studies.length
                  ? 'form-select'
                  : 'form-select pe-none user-select-none'
              }
              onChange={handleStudyChange}
              value={study?.id || ''}
            >
              {site.studies.map((s) => (
                <option key={s.id} value={s.id}>
                  {s.name}
                </option>
              ))}
            </select>
          </Col>
        </Row>
      </Col>
      <Col className="d-flex justify-content-end px-0">
        <PatientSearch />
        <Button
          onClick={() => navigate('/feasibility')}
          className="align-self-center ms-1"
          variant="outline-info"
        >
          Feasibility
          <small>
            <Badge className="ms-1" bg="info">
              BETA
            </Badge>
          </small>
        </Button>
      </Col>
    </Row>
  );
}
