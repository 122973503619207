/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
import { DateTime } from 'luxon';
import React, { useContext, useMemo, useCallback } from 'react';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  Dash,
  FileEarmark,
  ShieldLock,
  ExclamationCircleFill,
} from 'react-bootstrap-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { ScheduleContext } from './ScheduleProvider';
import TableLayout from 'components/Layouts/TableLayout';
import LoadingIndicator from 'components/LoadingIndicator';
import PatientStudyTableDisplay from 'components/PatientStudyTableDisplay';
import ReadonlyIcon from 'components/ReadonlyIcon';
import RecentNoteIcon from 'components/RecentNoteIcon';
import AppointmentTypes from 'entities/AppointmentTypes';
import { usePagedSiteAppointments } from 'hooks/siteAppointmentHooks';

function PatientsAppointmentsTable() {
  const { stateValues, handlePaginationChange, handleSortChange } =
    useContext(ScheduleContext);

  const {
    siteId,
    studyIds,
    registryIds,
    studyStatusIds,
    appointmentStatusIds,
    startDate,
    endDate,
    providerName,
    appointmentTypeId,
    pageSize,
    pageIndex,
    sortField,
    sortDirection,
  } = stateValues;

  const pagination = { pageIndex, pageSize };
  const sorting = [{ id: sortField, desc: sortDirection === 'DESC' }];

  const { data, isSuccess, isLoading } = usePagedSiteAppointments(
    {
      siteId,
      studyIds,
      registryIds,
      studyStatusIds,
      appointmentStatusIds,
      startDate,
      endDate,
      providerName,
      appointmentTypeId,
      pageIndex,
      pageSize,
      sortField,
      sortDirection,
    },
    pagination,
    sorting
  );

  const navigate = useNavigate();
  const location = useLocation();

  const viewPatient = useCallback(
    (patientId) => {
      navigate(`/patients/${patientId}`, {
        state: { backPath: location.pathname + location.search },
      });
    },
    [location.pathname, location.search, navigate]
  );

  const columns = useMemo(() => {
    /** @type {Array<object>} */
    const colDefs = [
      {
        id: 'patient.external',
        accessorFn: (row) => row.patient.external,
        header: () => <ShieldLock />,
        meta: {
          headerProps: { className: 'text-center', style: { width: '32px' } },
          cellProps: { className: 'text-center' },
        },
        cell: (props) => (
          <ReadonlyIcon readonly={props.getValue()} tooltip="External" />
        ),
      },
      {
        id: 'patient.fullName',
        accessorFn: (row) => row.patient.fullName,
        header: 'Name',
        meta: { headerProps: { className: 'display-4' } },
        cell: (props) => {
          if (!props.row.original.patient.doNotContact)
            return (
              <div>
                <div>{props.getValue()}</div>
                <div className="text-muted">
                  MRN - {props.row.original.patient.medicalRecordNumber}
                </div>
              </div>
            );

          return (
            <div>
              <div className="d-flex align-items-center">
                {props.getValue()}
                <OverlayTrigger
                  overlay={<Tooltip>Do Not Contact</Tooltip>}
                  placement="top"
                >
                  <ExclamationCircleFill className="text-danger ms-1" />
                </OverlayTrigger>
              </div>
              <div className="text-muted">
                MRN - {props.row.original.patient.medicalRecordNumber}
              </div>
            </div>
          );
        },
      },
      {
        id: 'patient.patientStudies',
        accessorFn: (row) => {
          const ps = row.patient.patientStudies;

          if (ps.length === 0) {
            return '';
          }

          if (ps.length === 1) {
            return ps[0].study.name;
          }

          return `${ps.length} Studies`;
        },
        header: 'Study',
        enableSorting: false,
        meta: {
          headerProps: { className: 'display-4 fw-bold text-uppercase' },
        },
        cell: (props) => (
          <PatientStudyTableDisplay
            cellDisplay={props.getValue()}
            patientStudies={props.row.original.patient.patientStudies}
          />
        ),
      },
      {
        accessorKey: 'appointmentDate',
        header: 'Appointment',
        meta: { headerProps: { className: 'display-4' } },
        cell: (props) => {
          const {
            appointmentDate,
            reason,
            patient: {
              site: { zone },
            },
          } = props.row.original;

          if (appointmentDate) {
            const timeZone = zone.name;
            const formattedDate = DateTime.fromISO(appointmentDate, {
              zone: timeZone,
            }).toFormat('MM/dd/yy t ZZZZ');

            return (
              <div>
                <div>{formattedDate}</div>
                <div className="text-muted">{reason}</div>
              </div>
            );
          }
          return <Dash />;
        },
      },
      {
        id: 'appointmentStatus.name',
        accessorFn: (row) => row.appointmentStatus?.name,
        header: 'Appt Status',
        meta: { headerProps: { className: 'display-4' } },
      },
      {
        id: 'patient.remarks',
        accessorFn: (row) => row.patient.remarks,
        header: () => <FileEarmark />,
        enableSorting: false,
        meta: {
          headerProps: { className: 'text-center', style: { width: '32px' } },
          cellProps: { className: 'text-center' },
        },
        cell: (props) => (
          <RecentNoteIcon
            show={!!props.row.original.patient.remarks.length}
            id={props.row.original.patient.id}
          />
        ),
      },
    ];

    if (appointmentTypeId === AppointmentTypes.FACILITY) {
      colDefs.splice(4, 1, {
        accessorKey: 'visitProviderLastName',
        header: 'Provider',
        meta: { headerProps: { className: 'display-4' } },
        cell: (props) => {
          const {
            visitProviderLastName: lastName,
            visitProviderFirstName: firstName,
          } = props.row.original;

          if (!lastName) {
            return <Dash />;
          }

          return (
            <span>{[lastName, firstName].filter(Boolean).join(', ')}</span>
          );
        },
      });
    }

    return colDefs;
  }, [appointmentTypeId]);

  const getRowProps = useCallback(
    (row) => ({
      className: 'hoverable-row',
      onClick: () => viewPatient(row.original.patientId),
    }),
    [viewPatient]
  );

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    isSuccess && (
      <Row className="pt-3 row-cols-xl-1">
        <Col>
          <TableLayout
            columns={columns}
            data={data}
            pagination={pagination}
            setPagination={handlePaginationChange}
            sorting={sorting}
            setSorting={handleSortChange}
            getRowProps={getRowProps}
            noResultsText="0 patient appointments."
          />
        </Col>
      </Row>
    )
  );
}

export default PatientsAppointmentsTable;
