/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Dash } from 'react-bootstrap-icons';
import TableLayout from 'components/Layouts/TableLayout';
import LoadingIndicator from 'components/LoadingIndicator';
import SortDirection from 'entities/SortDirection';
import { usePatientChartReviews } from 'hooks/patientChartReviewHooks';
import { formatDateDisplay } from 'pages/utils/dateTimeUtils';

export default function ChartReviewHistoryTable({ patientContext }) {
  const { patientId } = patientContext;

  const { data, isLoading } = usePatientChartReviews(
    patientId,
    SortDirection.DESC
  );

  const columns = useMemo(() => {
    const config = [
      {
        accessorKey: 'createdAt',
        header: 'DATE',
        meta: {
          headerProps: { className: 'text-center' },
          cellProps: { className: 'text-center text-nowrap' },
        },
        cell: (props) => formatDateDisplay(props.getValue()),
      },
      {
        id: 'study.name',
        accessorFn: (row) => row.study?.name,
        header: 'STUDY',
        cell: (props) => props.getValue() ?? <Dash />,
      },
      {
        accessorKey: 'reason',
        header: 'REASON',
        cell: (props) => props.getValue() || <Dash />,
      },
      {
        id: 'referralSource.name',
        accessorFn: (row) => row.referralSource?.name,
        header: 'REFERRAL SOURCE',
        cell: (props) => props.getValue() ?? <Dash />,
      },
      {
        id: 'studyStatus.name',
        accessorFn: (row) => row.studyStatus?.name,
        header: 'REVIEW STATUS',
        cell: (props) => props.getValue() ?? <Dash />,
      },
      {
        id: 'createdBy.fullName',
        accessorFn: (row) => row.createdBy?.fullName,
        header: 'USER',
        meta: { cellProps: { className: 'text-nowrap' } },
        cell: (props) => props.getValue() ?? <Dash />,
      },
    ];

    return config;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <TableLayout
      columns={columns}
      data={data}
      noResultsText="0 chart reviews found."
    />
  );
}

ChartReviewHistoryTable.propTypes = {
  patientContext: PropTypes.shape({
    patientId: PropTypes.number.isRequired,
    siteId: PropTypes.number,
  }).isRequired,
};
