import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import TableLayout from 'components/Layouts/TableLayout';
import { formatDateDisplay } from 'pages/utils/dateTimeUtils';

export default function PatientRegistriesSection({ patientRegistries = [] }) {
  const columns = useMemo(
    () => [
      {
        accessorKey: 'study.name',
        header: 'Name',
        meta: {
          headerProps: {
            className: 'display-4 fw-bold text-uppercase',
            style: { width: '70%' },
          },
        },
      },
      {
        accessorKey: 'createdAt',
        header: 'Added to Registry',
        meta: {
          headerProps: {
            className: 'text-center display-4 fw-bold text-uppercase',
          },
          cellProps: { className: 'text-center' },
        },
        cell: ({ getValue }) => formatDateDisplay(getValue()),
      },
    ],
    []
  );

  return (
    <>
      <div className="display-3">Registries</div>
      <TableLayout
        className="mt-1"
        columns={columns}
        data={patientRegistries}
      />
    </>
  );
}

PatientRegistriesSection.defaultProps = {
  patientRegistries: [],
};

PatientRegistriesSection.propTypes = {
  patientRegistries: PropTypes.arrayOf(PropTypes.shape({})),
};
