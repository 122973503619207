import PropTypes from 'prop-types';
import React from 'react';
import {
  Row,
  Col,
  Form,
  FormLabel,
  FormGroup,
  FormText,
  FormControl,
} from 'react-bootstrap';
import * as yup from 'yup';
import SelectField from 'components/FormikForm/SelectField';
import LoadingIndicator from 'components/LoadingIndicator';
import { useSites } from 'hooks/siteHooks';

const initialValues = {
  measurement: {
    height: { value: '', units: 'inches' },
    weight: { value: '', units: 'lbs' },
    bmi: { value: '', units: 'kg/m^2' },
    waist: { value: '', units: 'inches' },
  },
  procedure_result: {
    kpa: { value: '', units: 'kilopascals' },
    cap: { value: '', units: 'dB/m' },
    iqr: { value: '', units: '%' },
    fscore: { value: '', units: '' },
  },
  siteId: undefined,
};

const schema = yup.object({
  procedure_result: yup.object().shape(
    {
      kpa: yup.object().when(['cap.value', 'iqr.value'], {
        is: (cap, iqr) => cap || iqr,
        then: () => yup.object({ value: yup.number().required() }),
        otherwise: () => yup.object({ value: yup.number().nullable() }),
      }),
      cap: yup.object().when(['kpa.value', 'iqr.value'], {
        is: (kpa, iqr) => kpa || iqr,
        then: () => yup.object({ value: yup.number().required() }),
        otherwise: () => yup.object({ value: yup.number().nullable() }),
      }),
      iqr: yup.object().when(['kpa.value', 'cap.value'], {
        is: (kpa, cap) => kpa || cap,
        then: () => yup.object({ value: yup.number().required() }),
        otherwise: () => yup.object({ value: yup.number().nullable() }),
      }),
      fscore: yup.object({ value: yup.number().required() }),
    },
    [
      ['kpa', 'cap'],
      ['kpa', 'iqr'],
      ['cap', 'kpa'],
      ['cap', 'iqr'],
      ['iqr', 'kpa'],
      ['iqr', 'cap'],
    ]
  ),
  measurement: yup.object().shape({
    height: yup.object({ value: yup.number().required() }),
    weight: yup.object({ value: yup.number().required() }),
    bmi: yup.object({ value: yup.number().nullable() }),
    waist: yup.object({ value: yup.number().nullable() }),
  }),
  siteId: yup.number().required(),
});

const calculateBMI = async (values, setFieldValue) => {
  const height = values.measurement?.height?.value;
  const weight = values.measurement?.weight?.value;

  if (height && weight) {
    const value = ((Number(weight) / Number(height) ** 2) * 703).toFixed(1);
    await setFieldValue('measurement[bmi][value]', value);
  }
};

/** @type {React.ForwardRefExoticComponent<any, any>} */
const NashPrescreenForm = React.forwardRef(
  (
    { handleSubmit, handleChange, setFieldValue, values, errors, allowChanges },
    ref
  ) => {
    const sites = useSites();

    if (sites.isLoading) return <LoadingIndicator />;

    return (
      <Form ref={ref} onSubmit={handleSubmit}>
        <fieldset disabled={!allowChanges}>
          <span className="d-none">
            <Row className="mb-2">
              <SelectField
                labelClassName="col-3 text-end pe-3"
                controlClassName="w-50 d-inline ms-2"
                label="Site"
                name="siteId"
                defaultDisplay="Select Site"
                options={sites.data}
                valueAccessor="id"
                displayAccessor="name"
              />
            </Row>
          </span>

          <Row>
            <Col className="border-bottom border-lighter">
              <h6>Measurements</h6>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <FormGroup
                as={Row}
                controlId="ctl-measurement-height"
                className="mb-0 align-items-baseline"
              >
                <FormLabel column md={3} className="text-md-end">
                  Height
                </FormLabel>
                <Col md={4} className="d-inline-flex">
                  <FormControl
                    type="number"
                    size="sm"
                    style={{ width: '100px' }}
                    name="measurement[height][value]"
                    placeholder={allowChanges ? '65' : ''}
                    onBlur={() => calculateBMI(values, setFieldValue)}
                    onChange={handleChange}
                    value={values?.measurement?.height?.value}
                    isInvalid={!!errors.measurement?.height?.value}
                  />
                  <Form.Text muted className="ms-2 fst-italic">
                    inches
                  </Form.Text>
                </Col>
              </FormGroup>
              <FormGroup
                as={Row}
                controlId="ctl-measurement-height"
                className="mb-0 align-items-baseline"
              >
                <FormLabel column md={3} className="text-md-end">
                  Weight
                </FormLabel>
                <Col md={4} className="d-inline-flex">
                  <FormControl
                    type="number"
                    size="sm"
                    style={{ width: '100px' }}
                    name="measurement[weight][value]"
                    placeholder={allowChanges ? '150' : ''}
                    onBlur={() => calculateBMI(values, setFieldValue)}
                    onChange={handleChange}
                    value={values?.measurement?.weight?.value}
                    isInvalid={!!errors.measurement?.weight?.value}
                  />
                  <FormText muted className="ms-2 fst-italic">
                    lbs
                  </FormText>
                </Col>
              </FormGroup>
              <FormGroup
                as={Row}
                controlId="ctl-measurement-height"
                className="mb-0 align-items-baseline"
              >
                <Form.Label column md={3} className="text-md-end">
                  BMI
                </Form.Label>
                <Col md={4} className="d-inline-flex">
                  <FormControl
                    type="number"
                    size="sm"
                    readOnly
                    style={{ width: '100px' }}
                    name="measurement[bmi][value]"
                    placeholder={allowChanges ? '25' : ''}
                    onChange={handleChange}
                    value={values?.measurement?.bmi?.value}
                    isInvalid={!!errors.measurement?.bmi?.value}
                  />
                  <Form.Text muted className="ms-2 fst-italic">
                    kg/m<sup>2</sup>
                  </Form.Text>
                </Col>
              </FormGroup>
              <FormGroup
                as={Row}
                controlId="ctl-measurement-waist"
                className="mb-0 align-items-baseline"
              >
                <FormLabel column md={3} className="text-md-end">
                  Waist
                </FormLabel>
                <Col md={4} className="d-inline-flex">
                  <FormControl
                    type="number"
                    size="sm"
                    style={{ width: '100px' }}
                    name="measurement[waist][value]"
                    placeholder={allowChanges ? '32' : ''}
                    onChange={handleChange}
                    value={values?.measurement?.waist?.value}
                    isInvalid={!!errors.measurement?.waist?.value}
                  />
                  <FormText muted className="ms-2 fst-italic">
                    inches
                  </FormText>
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="border-bottom border-lighter">
              <h6>Fibroscan</h6>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <FormGroup
                as={Row}
                controlId="ctl-procedure-result-kpa"
                className="mb-0 align-items-baseline"
              >
                <FormLabel column md={3} className="text-md-end">
                  kPa
                </FormLabel>
                <Col md={4} className="d-inline-flex">
                  <FormControl
                    type="number"
                    size="sm"
                    style={{ width: '100px' }}
                    name="procedure_result[kpa][value]"
                    placeholder={allowChanges ? '6.5' : ''}
                    onChange={handleChange}
                    value={values?.procedure_result?.kpa?.value}
                    isInvalid={!!errors.procedure_result?.kpa?.value}
                  />
                  <FormText muted className="ms-2 fst-italic">
                    kilopascals
                  </FormText>
                </Col>
              </FormGroup>
              <FormGroup
                as={Row}
                controlId="ctl-procedure-result-cap"
                className="mb-0 align-items-baseline"
              >
                <FormLabel column md={3} className="text-md-end">
                  CAP
                </FormLabel>
                <Col md={4} className="d-inline-flex">
                  <FormControl
                    type="number"
                    size="sm"
                    style={{ width: '100px' }}
                    name="procedure_result[cap][value]"
                    placeholder={allowChanges ? '300' : ''}
                    onChange={handleChange}
                    value={values?.procedure_result?.cap?.value}
                    isInvalid={!!errors.procedure_result?.cap?.value}
                  />
                  <FormText muted className="ms-2 fst-italic">
                    dB/m
                  </FormText>
                </Col>
              </FormGroup>
              <FormGroup
                as={Row}
                controlId="ctl-measurement-iqr"
                className="mb-0 align-items-baseline"
              >
                <FormLabel column md={3} className="text-md-end">
                  kPa IQR/med
                </FormLabel>
                <Col md={4} className="d-inline-flex">
                  <FormControl
                    type="number"
                    size="sm"
                    style={{ width: '100px' }}
                    name="procedure_result[iqr][value]"
                    placeholder={allowChanges ? '15' : ''}
                    onChange={handleChange}
                    value={values?.procedure_result?.iqr?.value}
                    isInvalid={!!errors.procedure_result?.iqr?.value}
                  />
                  <FormText muted className="ms-2 fst-italic">
                    %
                  </FormText>
                </Col>
              </FormGroup>
              <FormGroup
                as={Row}
                controlId="ctl-measurement-fscore"
                className="mb-0 align-items-baseline"
              >
                <FormLabel column md={3} className="text-md-end">
                  F-score
                </FormLabel>
                <Col md={4} className="d-inline-flex">
                  <FormControl
                    type="number"
                    size="sm"
                    style={{ width: '100px' }}
                    name="procedure_result[fscore][value]"
                    placeholder={allowChanges ? '2' : ''}
                    onChange={handleChange}
                    value={values?.procedure_result?.fscore?.value}
                    isInvalid={!!errors.procedure_result?.fscore?.value}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
        </fieldset>
      </Form>
    );
  }
);

NashPrescreenForm.propTypes = {
  values: PropTypes.shape({
    measurement: PropTypes.shape({
      waist: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      bmi: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      weight: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      height: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    }),
    procedure_result: PropTypes.shape({
      fscore: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      iqr: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      cap: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      kpa: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    }),
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    procedure_result: PropTypes.shape({
      fscore: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      iqr: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      cap: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      kpa: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    }),
    measurement: PropTypes.shape({
      waist: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      bmi: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      weight: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      height: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    }),
  }).isRequired,
  allowChanges: PropTypes.bool.isRequired,
};

NashPrescreenForm.initialValues = initialValues;
NashPrescreenForm.schema = schema;

export default NashPrescreenForm;
