import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Row,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
  ToastContainer,
  Toast,
  Badge,
} from 'react-bootstrap';
import {
  Check2Circle,
  CheckCircleFill,
  XCircleFill,
} from 'react-bootstrap-icons';

import { useCreatePatientResearchProfile } from 'hooks/patientResearchProfileHooks';

export default function PatientInfoHeader({
  name,
  patientId,
  doNotContact,
  isCrioButtonDisabled,
  isCrioButtonVisible,
}) {
  const [showSuccess, setShowSuccess] = useState(false);
  const [showDanger, setShowDanger] = useState(false);

  const { mutate: createPatientResearchProfile, isLoading } =
    useCreatePatientResearchProfile({
      onSuccess: () => {
        setShowSuccess(true);
      },
      onError: () => {
        setShowDanger(true);
      },
    });

  const handleAddPatientToCrioClick = () => {
    createPatientResearchProfile({ patientId });
  };

  return (
    <Row>
      <ToastContainer position="top-end" style={{ marginTop: '12px' }}>
        <Toast
          bg="success"
          show={showSuccess}
          delay={3000}
          onClose={() => setShowSuccess(false)}
          autohide
          className="shadow"
        >
          <Toast.Header>
            <CheckCircleFill fill="green" size={20} />
            <p className="ms-2 my-auto me-auto text-black">
              <strong>Success</strong>: Request sent to add patient to CRIO.
            </p>
          </Toast.Header>
        </Toast>

        <Toast
          bg="danger"
          show={showDanger}
          delay={3000}
          onClose={() => setShowDanger(false)}
          autohide
          className="shadow"
        >
          <Toast.Header>
            <XCircleFill fill="red" size={20} />
            <p className="ms-2 my-auto me-auto text-black">
              <strong>Error</strong>: Failure to add patient to CRIO.
            </p>
          </Toast.Header>
        </Toast>
      </ToastContainer>
      <Col>
        <div className="d-flex">
          <div className="display-2">{name}</div>
          {doNotContact && (
            <div className="ms-3">
              <Badge bg="danger" className="mt-1 p-2">
                <span className="ms-1 align-middle">Do Not Contact</span>
              </Badge>
            </div>
          )}
        </div>
      </Col>
      {isCrioButtonVisible && (
        <Col className="align-self-center">
          <Row className="text-end">
            <Col className="align-self-center text-nowrap">
              <OverlayTrigger
                key="add-patient-to-crio-overlay"
                placement="top"
                overlay={
                  <Tooltip id="add-patient-to-crio-tooltip">
                    Add Patient to CRIO
                  </Tooltip>
                }
              >
                <Button
                  className="me-2"
                  variant="primary"
                  onClick={handleAddPatientToCrioClick}
                  disabled={isLoading || isCrioButtonDisabled}
                >
                  <Check2Circle /> CRIO
                </Button>
              </OverlayTrigger>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
}

PatientInfoHeader.propTypes = {
  name: PropTypes.string.isRequired,
  patientId: PropTypes.number.isRequired,
  doNotContact: PropTypes.bool.isRequired,
  isCrioButtonDisabled: PropTypes.bool.isRequired,
  isCrioButtonVisible: PropTypes.bool.isRequired,
};
