import { useQuery } from '@tanstack/react-query';
import axios from '../axios';

export default function useStatusGroupMetrics(siteId, studyId) {
  return useQuery(
    ['status-group-metrics', siteId, studyId],
    () =>
      axios
        .get('/api/status-groups/metrics', { params: { siteId, studyId } })
        .then((res) => res.data),
    {
      enabled: Boolean(siteId) && Boolean(studyId),
      keepPreviousData: true,
    }
  );
}
